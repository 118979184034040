import React, { useEffect, useState } from 'react';
import { Button, Descriptions, Flex, Image, List, Modal, Space, Tabs, Tag, theme } from 'antd';
import {
  ClockCircleOutlined,
  CloseOutlined,
  ExportOutlined,
  InfoCircleOutlined,
  WarningTwoTone,
} from '@ant-design/icons';
import { testTubeColorProperties } from '../../utils/enumHelpers';
import { TestTubeColor } from '../../components/TestTubeColor';
import { openExternalLink } from '../../utils/linkOpener';
import { humanizedHours } from '../../utils/dateFormatUtils';
import { currency } from '../../utils/currency';
import { BillingType, ParameterSelection_RequestableParameterFragment } from '../../graphql/generated/graphql.ts';
import { useAuth } from 'react-oidc-context';
import { gold } from '@ant-design/colors';

const { useToken } = theme;

export const ParameterInfoModal: React.FC<{
  parameter: ParameterSelection_RequestableParameterFragment | null;
  flipParamNames: boolean;
  showPrices: boolean;
  onClose: () => void;
}> = ({ parameter, flipParamNames, showPrices, onClose }) => {
  const { token } = useToken();
  const auth = useAuth();
  const [imageUrls, setImageUrls] = useState<{ id: string; url: string }[]>([]);

  useEffect(() => {
    (async () => {
      setImageUrls([]);
      const result = [];
      for (const testTube of parameter?.specimens?.flatMap(it => it.testTubes) ?? []) {
        if (!testTube.hasImage) {
          continue;
        }
        const endpoint = window._env_.API_URL + `/rest/test-tube/${testTube.id}/imageUrl`;
        const response = await fetch(endpoint, {
          headers: { authorization: `Bearer ${auth.user?.access_token}` },
        });
        const url = await response.text();
        result.push({ id: testTube.id, url: url ?? '' });
      }
      setImageUrls(result);
    })();
  }, [parameter, auth]);

  const patientPrice = parameter?.billingInfos.find(it => it.billingType === BillingType.PATIENT)?.price;
  const doctorPrice = parameter?.billingInfos.find(it => it.billingType === BillingType.DOCTOR)?.price;
  const lgDoctorPrice = parameter?.billingInfos.find(it => it.billingType === BillingType.LG_DOCTOR)?.price;

  return (
    <Modal
      title={
        <>
          <InfoCircleOutlined /> Parameter-Informationen
        </>
      }
      okText="Schließen"
      okButtonProps={{ icon: <CloseOutlined /> }}
      open={parameter !== null}
      onOk={onClose}
      onCancel={onClose}
      cancelButtonProps={{ hidden: true }}
      width={768}
      destroyOnClose
    >
      <Tabs
        defaultActiveKey="parameter"
        size="small"
        items={[
          {
            key: 'parameter',
            label: 'Parameter',
            children: (
              <Descriptions column={1} size="small" bordered styles={{ label: { width: '200px' } }}>
                {flipParamNames ? (
                  <>
                    <Descriptions.Item label="Langbezeichnung">{parameter?.longName}</Descriptions.Item>
                    <Descriptions.Item label="Kurzname">{parameter?.shortName}</Descriptions.Item>
                  </>
                ) : (
                  <>
                    <Descriptions.Item label="Kurzname">{parameter?.shortName}</Descriptions.Item>
                    <Descriptions.Item label="Langbezeichnung">{parameter?.longName}</Descriptions.Item>
                  </>
                )}
                {!!parameter?.acute && (
                  <Descriptions.Item label="Akut anforderbar">
                    <ClockCircleOutlined /> Ja
                  </Descriptions.Item>
                )}
                {!!parameter?.synonyms?.length && (
                  <Descriptions.Item label="Synonyme">
                    <Flex gap="small" wrap>
                      {parameter?.synonyms?.map(it => (
                        <Tag key={it} style={{ margin: 0 }}>
                          {it}
                        </Tag>
                      ))}
                    </Flex>
                  </Descriptions.Item>
                )}
                {showPrices && patientPrice !== null && patientPrice !== undefined && (
                  <Descriptions.Item label="Preis Patient">{currency(patientPrice)}</Descriptions.Item>
                )}
                {showPrices && doctorPrice !== null && doctorPrice !== undefined && (
                  <Descriptions.Item label="Preis Zuweiser">{currency(doctorPrice)}</Descriptions.Item>
                )}
                {showPrices && lgDoctorPrice !== null && lgDoctorPrice !== undefined && (
                  <Descriptions.Item label="Preis LG-Zuweiser">{currency(lgDoctorPrice)}</Descriptions.Item>
                )}
                {showPrices &&
                  parameter?.billingInfos
                    ?.filter(
                      it => it.billingType === BillingType.SPECIAL_RATE && it.price !== null && it.price !== undefined
                    )
                    ?.map(it => (
                      <Descriptions.Item key={it.specialRateId} label={`Preis ${it.text}`}>
                        {currency(it.price)}
                      </Descriptions.Item>
                    ))}
                {!!parameter?.withoutParameterNames.length && (
                  <Descriptions.Item label="Nicht mit anforderbar mit">
                    {parameter?.withoutParameterNames.map(it => <Tag key={it}>{it}</Tag>)}
                  </Descriptions.Item>
                )}
                {!!parameter?.withParameterNames.length && (
                  <Descriptions.Item label="Nur anforderbar mit">
                    {parameter?.withParameterNames.map(it => <Tag key={it}>{it}</Tag>)}
                  </Descriptions.Item>
                )}
                {!!parameter?.volume && (
                  <Descriptions.Item label="Benötigtes Volumen">{parameter?.volume}</Descriptions.Item>
                )}
                <Descriptions.Item label="Labor">
                  {parameter?.lab.name} ({parameter?.lab.shortName})
                </Descriptions.Item>
                {!!parameter?.evalHours && parameter.evalHours !== -1 && (
                  <Descriptions.Item label="Auswertungsdauer">{humanizedHours(parameter.evalHours)}</Descriptions.Item>
                )}
                {!!parameter?.importantDescription && (
                  <Descriptions.Item
                    label={
                      <Space>
                        <WarningTwoTone twoToneColor={gold[5]} style={{ fontSize: '16px' }} /> Wichtige Informationen
                      </Space>
                    }
                  >
                    <div style={{ whiteSpace: 'pre-wrap' }}>{parameter?.importantDescription}</div>
                  </Descriptions.Item>
                )}
                {!!parameter?.description && (
                  <Descriptions.Item label="Informationen">
                    <div style={{ whiteSpace: 'pre-wrap' }}>{parameter?.description}</div>
                  </Descriptions.Item>
                )}
                {!!parameter?.billingDescription && (
                  <Descriptions.Item label="Verrechnungsinformationen">
                    <div style={{ whiteSpace: 'pre-wrap' }}>{parameter?.billingDescription}</div>
                  </Descriptions.Item>
                )}
                {!!parameter?.link && (
                  <Descriptions.Item label="Weitere Informationen">
                    <Button
                      type="link"
                      style={{ height: 'auto', padding: 0 }}
                      icon={<ExportOutlined />}
                      onClick={() => openExternalLink(parameter?.link)}
                    >
                      Öffnen
                    </Button>
                  </Descriptions.Item>
                )}
              </Descriptions>
            ),
          },
          {
            key: 'material',
            label: 'Material & Probenbehälter',
            children: (
              <List
                itemLayout="vertical"
                locale={{
                  emptyText: 'Für diesen Parameter wird kein Material benötigt',
                }}
                dataSource={parameter?.specimens}
                renderItem={specimen => {
                  return (
                    <Descriptions
                      key={specimen.id}
                      column={1}
                      size="small"
                      bordered
                      styles={{ label: { width: '200px' } }}
                      style={{ marginBottom: token.paddingSM }}
                    >
                      <Descriptions.Item label="Bezeichnung">{specimen.name}</Descriptions.Item>
                      <Descriptions.Item label="Materialkennung">{specimen.classification}</Descriptions.Item>
                      <Descriptions.Item label="Lagerdauer">{humanizedHours(specimen.storagePeriod)}</Descriptions.Item>
                      <Descriptions.Item label="Probenbehälter">
                        {!specimen.testTubes.length && <>Keine Probenbehälter benötigt</>}
                        {specimen.testTubes.map(testTube => {
                          const url = imageUrls.find(iu => iu.id === testTube.id)?.url;
                          return (
                            <Descriptions
                              key={testTube.id}
                              column={1}
                              size="small"
                              bordered
                              styles={{ label: { width: '200px' } }}
                              style={{ marginBottom: token.paddingSM }}
                            >
                              <Descriptions.Item label="Bezeichnung">{testTube.name}</Descriptions.Item>
                              <Descriptions.Item label="Farbe">
                                <TestTubeColor properties={testTubeColorProperties(testTube.color)} withText />
                              </Descriptions.Item>
                              <Descriptions.Item label="Etiketten-Text">{testTube.labelText}</Descriptions.Item>
                              {!!testTube.labelText2 && (
                                <Descriptions.Item label="Etiketten-Text 2">{testTube.labelText2}</Descriptions.Item>
                              )}
                              {!!testTube?.volume && (
                                <Descriptions.Item label="Volumen">{testTube?.volume}</Descriptions.Item>
                              )}
                              {!!testTube.link && (
                                <Descriptions.Item label="Onlineshop">
                                  <Button
                                    type="link"
                                    style={{ height: 'auto', padding: 0 }}
                                    icon={<ExportOutlined />}
                                    onClick={() => openExternalLink(testTube.link)}
                                  >
                                    Hier online bestellen
                                  </Button>
                                </Descriptions.Item>
                              )}
                              {!!url && (
                                <Descriptions.Item label="Abbildung">
                                  <Image style={{ maxHeight: '150px' }} src={url} />
                                </Descriptions.Item>
                              )}
                            </Descriptions>
                          );
                        })}
                      </Descriptions.Item>
                    </Descriptions>
                  );
                }}
              />
            ),
          },
        ]}
      />
    </Modal>
  );
};
