import { Tag, theme } from 'antd';
import React from 'react';
import { findRequestStatusMetadata } from '../utils/enumHelpers';
import { RequestStatus } from '../graphql/generated/graphql.ts';
import { css } from '@emotion/css';

export const RequestStatusBadge: React.FC<{
  status: RequestStatus;
}> = ({ status }) => {
  const { token } = theme.useToken();
  const { color, translation, iconType: IconComponent } = findRequestStatusMetadata(status, token);

  return (
    <Tag
      color={color}
      icon={<IconComponent />}
      className={css`
        margin: 0;
      `}
    >
      {translation}
    </Tag>
  );
};
