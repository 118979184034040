import { useMutation } from '@apollo/client';
import { graphql } from '../../../graphql/generated';

const CANCEL_ORDER_INVOICE_MUTATION = graphql(`
  mutation CancelOrderInvoice($orderId: ID!) {
    cancelOrderInvoice(orderId: $orderId) {
      id
    }
  }
`);

export const useCancelOrderInvoiceMutation = () => {
  return useMutation(CANCEL_ORDER_INVOICE_MUTATION);
};
