import { useMutation } from '@apollo/client';
import { graphql } from '../../../graphql/generated';

const UPDATE_REQUEST_COMMENT_MUTATION = graphql(`
  mutation UpdateRequestComment($requestId: ID!, $comment: String!) {
    updateRequestComment(requestId: $requestId, comment: $comment) {
      id
    }
  }
`);

export const useUpdateRequestCommentMutation = () => {
  return useMutation(UPDATE_REQUEST_COMMENT_MUTATION);
};
