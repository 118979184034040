import { App, Button, Descriptions, Tabs, Typography } from 'antd';
import { PrinterOutlined } from '@ant-design/icons';
import { Request } from './useRequestDetailsQuery.tsx';
import { translateIdCardType, translateInsuranceCategory } from '../../../utils/enumHelpers.ts';
import dayjs from 'dayjs';
import { SHORT_DATE_FORMAT } from '../../../utils/dateFormatUtils.ts';
import { Gender } from '../../../graphql/generated/graphql.ts';
import { patientAddress, patientFullName } from '../../../utils/patient.ts';
import { usePrintEmptyLabel } from '../../../hooks/usePrintEmptyLabel.ts';
import { useCurrentContextStore } from '../../../hooks/store/useCurrentContextStore.ts';

export const PatientTab = ({ request }: { request: Request }) => {
  const { message } = App.useApp();
  const printEmptyLabel = usePrintEmptyLabel();
  const { currentDoctorId } = useCurrentContextStore();
  const insuredPerson = request.patientData.insuredPerson;

  return (
    <Tabs
      defaultActiveKey="p"
      type="card"
      items={[
        {
          key: 'p',
          label: 'Patient',
          children: (
            <>
              <Button
                icon={<PrinterOutlined />}
                disabled={!window.nativeApi}
                onClick={() =>
                  printEmptyLabel(
                    {
                      doctorId: currentDoctorId,
                      patientTitle: request.patientData.title,
                      patientLastName: request.patientData.lastName,
                      patientFirstName: request.patientData.firstName,
                      patientSvnr: request.patientData.svnr,
                    },
                    1,
                    () => message.error('Beim Drucken ist ein Fehler aufgetreten'),
                    () => message.success('Leer-Etikett wurde an den Drucker gesendet')
                  )
                }
              >
                Leer-Etikett drucken
              </Button>
              <p />
              <Descriptions column={1} size="small" bordered styles={{ label: { width: '200px' } }}>
                <Descriptions.Item label="AIS-ID">{request.patientData.externalId}</Descriptions.Item>
                <Descriptions.Item label="Titel & Name">{patientFullName(request.patientData)}</Descriptions.Item>
                <Descriptions.Item label="Geburtstag">
                  {dayjs(request.patientData.birthday).format(SHORT_DATE_FORMAT)}
                </Descriptions.Item>
                <Descriptions.Item label="Geschlecht">
                  {request.patientData.gender === Gender.MALE ? 'männlich' : 'weiblich'}
                </Descriptions.Item>
                <Descriptions.Item label="SVNR">{request.patientData.svnr}</Descriptions.Item>
                <Descriptions.Item label="Kasse">
                  {request.insuranceShortName} - {request.insuranceName}
                </Descriptions.Item>
                <Descriptions.Item label="Versicherungskategorie">
                  {translateInsuranceCategory(request.patientData.insuranceCategory)}
                </Descriptions.Item>
                <Descriptions.Item label="Adresse">{patientAddress(request.patientData)}</Descriptions.Item>
                <Descriptions.Item label="Telefon">
                  <a href={`tel:${request.patientData.phone}`}>{request.patientData.phone}</a>
                </Descriptions.Item>
                <Descriptions.Item label="E-Mail">
                  <a href={`mailto:${request.patientData.email}`}>{request.patientData.email}</a>
                </Descriptions.Item>
                <Descriptions.Item label="Ausweis">
                  {translateIdCardType(request.patientData.idCardType)}
                </Descriptions.Item>
                <Descriptions.Item label="Ausweisnummer">{request.patientData.idCardNumber}</Descriptions.Item>
                <Descriptions.Item label="Ausstellende Behörde">
                  {request.patientData.idCardAuthority}
                </Descriptions.Item>
              </Descriptions>
            </>
          ),
        },
        {
          key: 'v',
          label: 'Hauptversicherte Person',
          children: insuredPerson ? (
            <Descriptions column={1} size="small" bordered styles={{ label: { width: '200px' } }}>
              <Descriptions.Item label="Titel & Name">{patientFullName(insuredPerson)}</Descriptions.Item>
              <Descriptions.Item label="Geburtstag">
                {dayjs(insuredPerson.birthday).format(SHORT_DATE_FORMAT)}{' '}
              </Descriptions.Item>
              {!!insuredPerson.gender && (
                <Descriptions.Item label="Geschlecht">
                  {insuredPerson.gender === Gender.MALE ? 'männlich' : 'weiblich'}
                </Descriptions.Item>
              )}
              <Descriptions.Item label="SVNR">{insuredPerson.svnr}</Descriptions.Item>
              <Descriptions.Item label="Kasse">
                {request.insuranceShortName} - {request.insuranceName}
              </Descriptions.Item>
              <Descriptions.Item label="Versicherungskategorie">
                {translateInsuranceCategory(request.patientData.insuranceCategory)}
              </Descriptions.Item>
              <Descriptions.Item label="Adresse">{patientAddress(insuredPerson)}</Descriptions.Item>
              <Descriptions.Item label="Telefon">
                <a href={`tel:${insuredPerson.phone}`}>{insuredPerson.phone}</a>
              </Descriptions.Item>
              <Descriptions.Item label="E-Mail">
                <a href={`mailto:${insuredPerson.email}`}>{insuredPerson.email}</a>
              </Descriptions.Item>
            </Descriptions>
          ) : (
            <Typography.Text type="secondary">Die Patient ist nicht mitversichert</Typography.Text>
          ),
        },
      ]}
    />
  );
};
