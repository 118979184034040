import React, { ReactNode, useEffect, useState } from 'react';
import { Alert, Button, Collapse, Divider, Form, Modal, Radio, Space, Tag, theme, Typography } from 'antd';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  InfoCircleOutlined,
  QuestionCircleOutlined,
  RightCircleOutlined,
} from '@ant-design/icons';
import { translateBillingType } from '../../../utils/enumHelpers';
import { LgBadge } from '../../../components/LgBadge';
import { css } from '@emotion/css';
import { currency } from '../../../utils/currency';
import { useAppStore } from '../../../hooks/store/useAppStore.ts';
import { useQuery } from '@apollo/client';
import {
  BillingInfo,
  BillingType,
  ParameterSelection_RequestableParameterFragment,
  Period,
} from '../../../graphql/generated/graphql.ts';
import { graphql } from '../../../graphql/generated';
import { LoadingIndicator } from '../../../components/LoadingIndicator.tsx';
import { useCurrentContextStore } from '../../../hooks/store/useCurrentContextStore.ts';

const { useToken } = theme;

const AVAILABLE_BILLING_INFOS_QUERY = graphql(`
  query AvailableBillingInfos(
    $catalogParamId: ID!
    $selectedParameters: [SelectedParameterInput!]!
    $doctorId: ID!
    $requestIdForReorder: ID!
    $patientData: PatientDataInput!
  ) {
    availableBillingInfos(
      catalogParamId: $catalogParamId
      patientData: $patientData
      doctorId: $doctorId
      requestIdForReorder: $requestIdForReorder
      selectedParameters: $selectedParameters
    ) {
      billingInfos {
        additionalText
        billingType
        diagnoseId
        price
        specialRateId
        text
      }
      exceededPeriodicGroups {
        periodicGroup {
          id
          name
          period
          maxCount
        }
        lg
        paramsInGroup
      }
    }
  }
`);

const BillingEntry: React.FC<{
  title: ReactNode;
  billingInfos: BillingInfo[];
  value: BillingInfo | null;
  onChange: (billingInfo: BillingInfo) => void;
  contentProvider: (billingInfo: BillingInfo) => ReactNode;
  addTextTitle?: boolean;
}> = ({ title, billingInfos, value, onChange, contentProvider, addTextTitle }) => {
  const { token } = useToken();
  const billingInfoToValue = (billingInfo: BillingInfo): string => {
    const billingType = billingInfo.billingType;
    let id = '';
    switch (billingInfo.billingType) {
      case BillingType.SPECIAL_RATE:
        id = billingInfo.specialRateId!;
        break;
      case BillingType.DIAGNOSE:
      case BillingType.LG_DIAGNOSE:
        id = billingInfo.diagnoseId!;
        break;
    }
    return billingType + ';' + id;
  };

  const valueToBillingInfo = (value: string): BillingInfo => {
    const [billingType, id] = value.split(';');
    const result = billingInfos.filter(it => it.billingType === billingType);
    switch (billingType) {
      case BillingType.SPECIAL_RATE:
        return result.find(it => it.specialRateId === id)!;
      case BillingType.DIAGNOSE:
      case BillingType.LG_DIAGNOSE:
        return result.find(it => it.diagnoseId === id)!;
    }
    return result[0];
  };

  return (
    <>
      <Divider
        className={css`
          margin: ${token.paddingSM}px 0;
        `}
      />
      <Form.Item
        label={
          <h4>
            {title}{' '}
            {addTextTitle && billingInfos.length && (
              <Typography.Text type="secondary">{billingInfos[0].additionalText}</Typography.Text>
            )}
          </h4>
        }
      >
        <Radio.Group
          onChange={e => onChange(valueToBillingInfo(e.target.value))}
          value={value ? billingInfoToValue(value) : null}
        >
          <Space direction="vertical">
            {billingInfos.map((billingInfo, i) => {
              return (
                <Radio key={billingInfo.billingType + '_' + i} value={billingInfoToValue(billingInfo)}>
                  {contentProvider(billingInfo)}
                </Radio>
              );
            })}
          </Space>
        </Radio.Group>
      </Form.Item>
    </>
  );
};

export const SelectBillingModal: React.FC<{
  skipEnabled: boolean;
  parameter: ParameterSelection_RequestableParameterFragment | null;
  defaultSelected: BillingInfo | null;
  forceSelection: boolean;
  onCancel: () => void;
  onSkip: () => void;
  onSelect: (parameter: ParameterSelection_RequestableParameterFragment, billingInfo: BillingInfo) => void;
  flipParamNames: boolean;
  showPrices: boolean;
}> = ({
  parameter,
  defaultSelected,
  onSelect,
  forceSelection,
  onCancel,
  onSkip,
  skipEnabled,
  flipParamNames,
  showPrices,
}) => {
  const [selectedBillingInfo, setSelectedBillingInfo] = useState<BillingInfo | null>(null);
  const { patientData, requestIdForReorder, paramQueue } = useAppStore();
  const { currentDoctorId } = useCurrentContextStore();
  const { token } = theme.useToken();

  const { data, loading } = useQuery(AVAILABLE_BILLING_INFOS_QUERY, {
    variables: {
      doctorId: currentDoctorId,
      patientData: patientData!,
      requestIdForReorder: requestIdForReorder,
      catalogParamId: parameter?.id ?? '',
      selectedParameters: paramQueue.selectedParameters.map(param => ({
        parameterId: param.id,
        parameterShortName: param.shortName,
        parameterLongName: param.longName,
        billingType: param.billingInfo.billingType,
        diagnoseId: param.billingInfo.diagnoseId,
        specialRateId: param.billingInfo.specialRateId,
      })),
    },
    skip: !parameter,
  });

  const billingInfos = data?.availableBillingInfos?.billingInfos ?? [];
  const billingTypes = billingInfos.map(bi => bi.billingType);

  useEffect(() => {
    setSelectedBillingInfo(defaultSelected);
  }, [defaultSelected]);

  const handleCancel = () => {
    onCancel();
    setSelectedBillingInfo(null);
  };

  const handleSkip = () => {
    onSkip();
    setSelectedBillingInfo(null);
  };

  return (
    <Modal
      title={
        <span>
          <QuestionCircleOutlined />{' '}
          {flipParamNames
            ? `${parameter?.longName} - ${parameter?.shortName}`
            : `${parameter?.shortName} - ${parameter?.longName}`}
        </span>
      }
      width={600}
      open={parameter !== null}
      closable={false}
      maskClosable={false}
      keyboard={false}
      footer={
        <>
          <Divider
            className={css`
              margin-bottom: ${token.marginSM}px;
              margin-top: ${token.marginSM}px;
            `}
          />
          <Space direction="horizontal" wrap>
            <Button hidden={forceSelection} onClick={handleCancel} icon={<CloseCircleOutlined />}>
              Abbrechen
            </Button>
            <Button hidden={forceSelection || !skipEnabled} onClick={handleSkip} icon={<RightCircleOutlined />}>
              Parameter überspringen
            </Button>
            <Button
              type="primary"
              icon={<CheckCircleOutlined />}
              disabled={!selectedBillingInfo}
              onClick={() => {
                onSelect(parameter!, selectedBillingInfo!);
                setSelectedBillingInfo(null);
              }}
            >
              Auswählen
            </Button>
          </Space>
        </>
      }
    >
      {loading ? (
        <LoadingIndicator height="200px" />
      ) : (
        <>
          {!!data?.availableBillingInfos?.exceededPeriodicGroups.length && (
            <Space
              direction="vertical"
              className={css`
                width: 100%;
                margin-bottom: ${token.marginSM}px;
              `}
            >
              {data?.availableBillingInfos?.exceededPeriodicGroups?.map((it, index) => {
                let periodText = '';
                switch (it.periodicGroup.period) {
                  case Period.CURRENT_REQUEST:
                    periodText = 'in dieser Anforderung (inkl. Nachforderungen)';
                    break;
                  case Period.CURRENT_DAY:
                    periodText = 'am heutigen Tag inkl. dieser Anforderung';
                    break;
                  case Period.CURRENT_QUARTER:
                    periodText = 'in diesem Quartal inkl. dieser Anforderung';
                    break;
                  case Period.CURRENT_YEAR:
                    periodText = 'in diesem Jahr inkl. dieser Anforderung';
                    break;
                }
                return (
                  <Alert
                    key={index}
                    type="warning"
                    message={`${it.lg ? 'LG-Kassenleistung' : 'Kassenleistung'} nicht verfügbar`}
                    description={
                      <Space direction="vertical">
                        <div>
                          Dieser Parameter gehört zu einer Gruppe <strong>{it.periodicGroup.name}</strong>. Aus dieser
                          Gruppe wurden <strong>{periodText}</strong> schon{' '}
                          <strong>{it.periodicGroup.maxCount} Parameter</strong> angefordert, alle weiteren können nicht
                          per {it.lg ? 'LG-Kassenleistung' : 'Kassenleistung'} verrechnet werden.
                        </div>
                        <Collapse
                          className={css`
                            .ant-collapse-header,
                            .ant-collapse-content-box {
                              padding: 0 !important;
                            }
                          `}
                          ghost
                          items={[
                            {
                              key: 'params',
                              label: (
                                <span
                                  className={css`
                                    color: ${token.colorLink};
                                  `}
                                >
                                  Alle Gruppen-Parameter anzeigen
                                </span>
                              ),
                              children: it.paramsInGroup.map((it, index) => (
                                <Tag
                                  key={`${it}_${index}`}
                                  className={css`
                                    margin-top: ${token.marginXS}px;
                                  `}
                                >
                                  {it}
                                </Tag>
                              )),
                            },
                          ]}
                        />
                      </Space>
                    }
                  />
                );
              })}
            </Space>
          )}
          {!!parameter?.billingDescription && <p style={{ whiteSpace: 'pre-wrap' }}>{parameter?.billingDescription}</p>}
          <h4>Bitte Verrechnung für das Labor {parameter?.lab.name} auswählen:</h4>
          <Form
            layout="vertical"
            className={css`
              .ant-form-item {
                margin-bottom: 0;
              }
            `}
          >
            {billingTypes.includes(BillingType.SPECIAL_RATE) && (
              <BillingEntry
                title="Sondertarif"
                billingInfos={billingInfos.filter(bi => bi.billingType === BillingType.SPECIAL_RATE)}
                value={selectedBillingInfo}
                onChange={setSelectedBillingInfo}
                contentProvider={billingInfo => (
                  <>
                    {billingInfo.text}{' '}
                    {showPrices && <Typography.Text type="secondary">{currency(billingInfo.price)}</Typography.Text>}
                  </>
                )}
              />
            )}
            {(billingTypes.includes(BillingType.DIAGNOSE) || billingTypes.includes(BillingType.LG_DIAGNOSE)) && (
              <BillingEntry
                title="Kassenleistung mit Diagnose"
                billingInfos={
                  billingInfos.filter(
                    bi => bi.billingType === BillingType.DIAGNOSE || bi.billingType === BillingType.LG_DIAGNOSE
                  ) ?? []
                }
                value={selectedBillingInfo}
                onChange={setSelectedBillingInfo}
                contentProvider={billingInfo => {
                  return (
                    <>
                      {billingInfo.billingType === BillingType.LG_DIAGNOSE && (
                        <>
                          <LgBadge />{' '}
                        </>
                      )}
                      {billingInfo.text}
                    </>
                  );
                }}
                addTextTitle
              />
            )}
            {(billingTypes.includes(BillingType.INSURANCE) || billingTypes.includes(BillingType.LG_INSURANCE)) && (
              <BillingEntry
                title="Kassenleistung"
                billingInfos={
                  billingInfos.filter(
                    bi => bi.billingType === BillingType.INSURANCE || bi.billingType === BillingType.LG_INSURANCE
                  ) ?? []
                }
                value={selectedBillingInfo}
                onChange={setSelectedBillingInfo}
                contentProvider={billingInfo => {
                  return (
                    <>
                      {billingInfo.billingType === BillingType.LG_INSURANCE && (
                        <>
                          <LgBadge />{' '}
                        </>
                      )}
                      {billingInfo.text}
                    </>
                  );
                }}
              />
            )}
            {(billingTypes.includes(BillingType.DOCTOR) ||
              billingTypes.includes(BillingType.PATIENT) ||
              billingTypes.includes(BillingType.LG_DOCTOR)) && (
              <BillingEntry
                title="Privat"
                billingInfos={
                  billingInfos.filter(
                    bi =>
                      bi.billingType === BillingType.DOCTOR ||
                      bi.billingType === BillingType.PATIENT ||
                      bi.billingType === BillingType.LG_DOCTOR
                  ) ?? []
                }
                value={selectedBillingInfo}
                onChange={setSelectedBillingInfo}
                contentProvider={billingInfo => {
                  return (
                    <>
                      {billingInfo.billingType === BillingType.LG_DOCTOR ? (
                        <>
                          <LgBadge /> Zuweiser
                        </>
                      ) : (
                        translateBillingType(billingInfo.billingType)
                      )}{' '}
                      {showPrices && <Typography.Text type="secondary">{currency(billingInfo.price)}</Typography.Text>}
                    </>
                  );
                }}
              />
            )}
            {billingTypes.includes(BillingType.DEFINE_LATER) && (
              <BillingEntry
                title={
                  <div>
                    Verrechnung später festlegen
                    <div
                      className={css`
                        font-weight: normal;
                        padding-top: 5px;
                      `}
                    >
                      <Typography.Text type="secondary">
                        <InfoCircleOutlined /> Die Anforderung kann dadurch nur vorbereitet und nicht direkt gesendet
                        werden. Spätestens zum Zeitpunkt des Sendens muss für jeden Parameter eine Auswahl getroffen
                        werden.
                      </Typography.Text>
                    </div>
                  </div>
                }
                billingInfos={billingInfos.filter(bi => bi.billingType === BillingType.DEFINE_LATER) ?? []}
                value={selectedBillingInfo}
                onChange={setSelectedBillingInfo}
                contentProvider={billingInfo => {
                  return translateBillingType(billingInfo.billingType);
                }}
              />
            )}
          </Form>
        </>
      )}
    </Modal>
  );
};
