import { FC } from 'react';
import { BillingInfo, BillingType } from '../../../graphql/generated/graphql.ts';
import { translateBillingType } from '../../../utils/enumHelpers.ts';
import { theme, Tooltip, Typography } from 'antd';
import { css, cx } from '@emotion/css';
import { ellipsis } from '../../../utils/ellipsis.ts';

export const Billing: FC<{ billingInfo: BillingInfo }> = ({ billingInfo }) => {
  const { token } = theme.useToken();

  let text;
  let tooltip = '';
  let errorStyle = false;

  switch (billingInfo.billingType) {
    case BillingType.SPECIAL_RATE:
    case BillingType.INSURANCE:
      text = billingInfo.text;
      break;
    case BillingType.LG_INSURANCE:
      text = `LG - ${billingInfo.text}`;
      break;
    case BillingType.DIAGNOSE:
      text = `Diag. - ${billingInfo.additionalText}`;
      tooltip = billingInfo.text;
      break;
    case BillingType.LG_DIAGNOSE:
      text = `LG - Diag. - ${billingInfo.additionalText}`;
      tooltip = billingInfo.text;
      break;
    case BillingType.LG_DOCTOR:
      text = 'LG - Zuweiser';
      break;
    case BillingType.DEFINE_LATER:
      text = translateBillingType(billingInfo.billingType);
      tooltip =
        'Verrechnungsinformation unbekannt. Die Anforderung kann dadurch nicht gesendet, sondern nur vorbereitet werden.';
      errorStyle = true;
      break;
    default:
      text = translateBillingType(billingInfo.billingType);
  }

  return (
    <Tooltip title={tooltip}>
      <Typography.Text
        type="secondary"
        className={cx(
          css`
            white-space: nowrap;
          `,
          errorStyle &&
            css`
              color: ${token.colorError} !important;
              font-weight: 500;
              text-transform: uppercase;
            `
        )}
      >
        <small>{ellipsis(text, 25)}</small>
      </Typography.Text>
    </Tooltip>
  );
};
