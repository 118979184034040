import { Table, Typography } from 'antd';
import { ClockCircleOutlined } from '@ant-design/icons';
import { Order, OrderParameter } from './useRequestDetailsQuery.tsx';
import { ColumnsType, ColumnType } from 'antd/es/table';
import { BillingTypeWithBadge } from '../../../components/BillingTypeWithBadge.tsx';
import { currency } from '../../../utils/currency.ts';
import { AlignType } from 'rc-table/lib/interface';
import { css } from '@emotion/css';
import { ellipsis } from '../../../utils/ellipsis.ts';

export const ParametersTab = ({
  order,
  showPrices,
  flipParamNames,
}: {
  order: Order;
  showPrices: boolean;
  flipParamNames: boolean;
}) => {
  const hasAcuteParams = order.orderParameters.some(it => it.acute) ?? false;

  const shortNameCol: ColumnType<OrderParameter> = {
    title: 'Kurzname',
    dataIndex: 'shortName',
    key: 'shortName',
    ellipsis: true,
    defaultSortOrder: flipParamNames ? undefined : 'ascend',
    width: 160,
    sorter: (a, b) => a.shortName.localeCompare(b.shortName),
    render: v => <Typography.Text style={{ fontWeight: flipParamNames ? 'normal' : 500 }}>{v}</Typography.Text>,
  };

  const longNameCol: ColumnType<OrderParameter> = {
    title: 'Langbezeichnung',
    dataIndex: 'longName',
    key: 'longName',
    ellipsis: true,
    defaultSortOrder: flipParamNames ? 'ascend' : undefined,
    width: 110,
    sorter: (a, b) => a.longName.localeCompare(b.longName),
    render: v => (
      <Typography.Text style={{ fontWeight: flipParamNames ? 500 : 'normal' }} title={v}>
        {ellipsis(v, 60)}
      </Typography.Text>
    ),
  };

  const paramColumns: ColumnsType<OrderParameter> = [
    ...(flipParamNames ? [longNameCol, shortNameCol] : [shortNameCol, longNameCol]),
    {
      title: 'Verrechnung',
      dataIndex: 'billingType',
      key: 'billingType',
      width: 120,
      sorter: (a, b) => a.billingType.localeCompare(b.billingType),
      render: value => <BillingTypeWithBadge billingType={value} />,
    },
    ...(showPrices
      ? [
          {
            title: 'Preis',
            dataIndex: 'price',
            key: 'price',
            width: 60,
            ellipsis: true,
            sorter: (a, b) => (a.price ?? -1) - (b.price ?? -1),
            render: value => currency(value),
          } as ColumnType<OrderParameter>,
        ]
      : []),
    ...(hasAcuteParams && order.acute
      ? [
          {
            title: 'Akut',
            dataIndex: 'acute',
            key: 'acute',
            width: 70,
            align: 'center' as AlignType,
            sorter: (a, b) => Number(a.acute) - Number(b.acute),
            render: (_, record) => {
              return record.acute ? (
                <ClockCircleOutlined
                  className={css`
                    font-size: 16px;
                  `}
                />
              ) : (
                ''
              );
            },
          } as ColumnType<OrderParameter>,
        ]
      : []),
    {
      title: 'Sondertarif',
      dataIndex: 'specialRateShortName',
      key: 'specialRateShortName',
      ellipsis: true,
      width: 110,
      sorter: (a, b) => a.specialRateShortName.localeCompare(b.specialRateShortName),
    },
  ];

  return (
    <Table<OrderParameter>
      scroll={{ y: '300px', x: 'max-content' }}
      rowKey={param => param.id}
      size="small"
      showSorterTooltip={false}
      dataSource={order.orderParameters}
      pagination={false}
      columns={paramColumns}
    />
  );
};
