import { useMutation } from '@apollo/client';
import { graphql } from '../../../graphql/generated';

const MARK_ORDER_LABEL_PRINTED_MUTATION = graphql(`
  mutation MarkOrderLabelPrinted($orderLabelId: ID!) {
    markOrderLabelPrinted(orderLabelId: $orderLabelId) {
      id
      printedAt
    }
  }
`);

export const useMarkOrderLabelPrintedMutation = () => {
  return useMutation(MARK_ORDER_LABEL_PRINTED_MUTATION);
};
