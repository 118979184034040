import React from 'react';
import { LgBadge } from './LgBadge';
import { translateBillingType } from '../utils/enumHelpers';
import { BillingType } from '../graphql/generated/graphql.ts';
import { Flex } from 'antd';

export const BillingTypeWithBadge: React.FC<{ billingType: BillingType }> = ({ billingType }) => {
  switch (billingType) {
    case BillingType.LG_DIAGNOSE:
      return (
        <Flex gap={5} align="center">
          <LgBadge /> Kasse-Diagnose
        </Flex>
      );
    case BillingType.LG_INSURANCE:
      return (
        <Flex gap={5} align="center">
          <LgBadge /> Kasse
        </Flex>
      );
    case BillingType.LG_DOCTOR:
      return (
        <Flex gap={5} align="center">
          <LgBadge /> Zuweiser
        </Flex>
      );
    default:
      return <>{translateBillingType(billingType)}</>;
  }
};
