import { Table, Tag, Typography } from 'antd';
import { Order, OrderFormField } from './useRequestDetailsQuery.tsx';
import { css } from '@emotion/css';
import { ColumnsType } from 'antd/es/table';
import { FormFieldType } from '../../../graphql/generated/graphql.ts';
import dayjs from 'dayjs';
import { SHORT_DATE_FORMAT } from '../../../utils/dateFormatUtils.ts';

export const FormsTab = ({ order }: { order: Order }) => {
  const formFieldColumns: ColumnsType<OrderFormField> = [
    {
      title: 'Bezeichnung',
      dataIndex: 'label',
      key: 'label',
      ellipsis: true,
      defaultSortOrder: 'ascend',
      sorter: (a, b) => a.label.localeCompare(b.label),
    },
    {
      title: 'Wert(e)',
      dataIndex: 'values',
      key: 'values',
      ellipsis: true,
      render: (_, record) => {
        if (record.type === FormFieldType.DATE) {
          return record.values.length ? <Tag>{dayjs(record.values[0]).format(SHORT_DATE_FORMAT)}</Tag> : '';
        }
        return record.values.map((v, i) => <Tag key={i}>{v}</Tag>);
      },
    },
  ];

  return order.orderForms.filter(it => it.fields.length).length ? (
    <div
      className={css`
        max-height: 400px;
        overflow: auto;
      `}
    >
      {order.orderForms
        .filter(it => it.fields.length)
        .map(form => {
          return (
            <Table<OrderFormField>
              key={form.id}
              title={() => (
                <h3
                  className={css`
                    margin-bottom: 0;
                  `}
                >
                  {form.header}
                </h3>
              )}
              rowKey={field => field.id}
              size="small"
              showSorterTooltip={false}
              dataSource={form.fields}
              pagination={false}
              columns={formFieldColumns}
              className={css`
                .ant-table-title {
                  padding-left: 0 !important;
                }
              `}
            />
          );
        })}
    </div>
  ) : (
    <Typography.Text type="secondary">Keine Formulare ausgefüllt</Typography.Text>
  );
};
