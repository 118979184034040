import { FC, useState } from 'react';
import { App, Button, Descriptions, Input, Space } from 'antd';
import { RequestStatusRow } from '../../../components/RequestStatusRow.tsx';
import dayjs from 'dayjs';
import { SHORT_DATETIME_FORMAT } from '../../../utils/dateFormatUtils.ts';
import { AdditionalDataTable } from '../../../components/AdditionalDataTable.tsx';
import { Request } from './useRequestDetailsQuery.tsx';
import { useUpdateRequestCommentMutation } from './useUpdateRequestCommentMutation.tsx';

export const RequestTab: FC<{ request: Request; onRefetchRequested: () => void }> = ({
  request,
  onRefetchRequested,
}) => {
  const { message } = App.useApp();
  const [comment, setComment] = useState(request.comment);
  const [updateRequestCommentMutation] = useUpdateRequestCommentMutation();

  const updateRequestComment = async () => {
    try {
      await updateRequestCommentMutation({
        variables: {
          requestId: request.id,
          comment: comment,
        },
      });
      onRefetchRequested();
    } catch (e) {
      console.log(e);
      message.error('Das Kommentar konnte nicht aktualisiert werden');
    }
  };

  return (
    <>
      <Descriptions column={1} size="small" bordered styles={{ label: { width: '200px' } }}>
        <Descriptions.Item label="Status">
          <RequestStatusRow request={request} />
        </Descriptions.Item>
        <Descriptions.Item label="Erstellt am">
          {dayjs(request.createdAt).format(SHORT_DATETIME_FORMAT)}
        </Descriptions.Item>
        <Descriptions.Item label="Erstellt von">
          {request.createdByFirstName} {request.createdByLastName}
        </Descriptions.Item>
        {!!request.modifiedAt && (
          <Descriptions.Item label="Zuletzt bearbeitet am">
            {dayjs(request.modifiedAt).format(SHORT_DATETIME_FORMAT)}
          </Descriptions.Item>
        )}
        {!!(request.modifiedByFirstName || request.modifiedByLastName) && (
          <Descriptions.Item label="Zuletzt bearbeitet von">
            {request.modifiedByFirstName} {request.modifiedByLastName}
          </Descriptions.Item>
        )}
        {!!request.sentAt && (
          <Descriptions.Item label="Gesendet am">
            {dayjs(request.sentAt).format(SHORT_DATETIME_FORMAT)}
          </Descriptions.Item>
        )}
        {!!(request.sentByFirstName || request.sentByLastName) && (
          <Descriptions.Item label="Gesendet von">
            {request.sentByFirstName} {request.sentByLastName}
          </Descriptions.Item>
        )}
        {!!request.sampledAt && (
          <Descriptions.Item label="Probenentnahme am">
            {dayjs(request.sampledAt).format(SHORT_DATETIME_FORMAT)}
          </Descriptions.Item>
        )}
        {!!request.importRequestId && (
          <Descriptions.Item label="Import-ID">{request.importRequestId}</Descriptions.Item>
        )}
        <Descriptions.Item label="Interner Kommentar">
          <Space direction="vertical" style={{ width: '100%' }}>
            <Input.TextArea rows={3} value={comment} onChange={e => setComment(e.target.value)} />
            <Button disabled={comment === request.comment} onClick={updateRequestComment}>
              Speichern
            </Button>
          </Space>
        </Descriptions.Item>
      </Descriptions>
      {!!request.additionalData && Object.keys(request.additionalData).length > 0 && (
        <>
          <p />
          <h3>Zusatzdaten</h3>
          <AdditionalDataTable additionalData={request.additionalData} inlineScroll />
        </>
      )}
    </>
  );
};
