import { useMutation } from '@apollo/client';
import { graphql } from '../../../graphql/generated';

const MARK_ORDER_FILE_PRINTED_MUTATION = graphql(`
  mutation MarkOrderFilePrinted($orderFileId: ID!) {
    markOrderFilePrinted(orderFileId: $orderFileId) {
      id
      printedAt
    }
  }
`);

export const useMarkOrderFilePrintedMutation = () => {
  return useMutation(MARK_ORDER_FILE_PRINTED_MUTATION);
};
