import React from 'react';
import { Segmented, theme } from 'antd';
import { css } from '@emotion/css';
// import { FileAddOutlined, FileProtectOutlined } from '@ant-design/icons';

export const AppSwitcher: React.FC = () => {
  const { token } = theme.useToken();

  if (window._env_.ENV_TAG === 'prod') {
    return (
      <span
        className={css`
          font-weight: 500;
          font-size: 20px;
        `}
      >
        Anforderungen
      </span>
    );
  }

  return (
    <Segmented
      className={css`
        margin: 0 ${token.marginLG}px 0 ${token.marginLG}px;
        @media (max-width: ${token.screenMD}px) {
          display: none;
        }
      `}
      size="large"
      defaultValue="Anforderungen"
      options={[
        { label: 'Anforderungen', value: 'Anforderungen' /*, icon: <FileAddOutlined />*/ },
        { label: 'Befunde', value: 'Befunde' /*, icon: <FileProtectOutlined />*/ },
      ]}
      onChange={value => {
        if (value === 'Befunde') {
          window.location.href = window._env_.BEFUNDE_URL;
        }
      }}
    />
  );
};
