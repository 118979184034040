import { useAuth } from 'react-oidc-context';

interface PrintDoctorLabelPayload {
  doctorIdForTemplate: string;
  orderId: string;
}

export const usePrintDoctorLabel = (): ((
  payload: PrintDoctorLabelPayload,
  count: number,
  onError: () => void,
  onSuccess: () => void
) => void) => {
  const auth = useAuth();

  return async (payload: PrintDoctorLabelPayload, count: number, onError: () => void, onSuccess: () => void) => {
    const url = window._env_.API_URL + '/rest/doctor-label?' + new URLSearchParams(Object.entries(payload)).toString();

    try {
      const response = await fetch(url, {
        headers: {
          authorization: `Bearer ${auth.user?.access_token}`,
        },
      });

      if (!response.ok) {
        onError();
        return;
      }

      const blob = await response.blob();
      const buffer = await blob.arrayBuffer();
      let concatenatedLabels = new ArrayBuffer(0);
      for (let index = 0; index < count; index++) {
        concatenatedLabels = await new Blob([concatenatedLabels, buffer]).arrayBuffer();
      }
      if (concatenatedLabels.byteLength > 0) {
        await window.nativeApi?.print(concatenatedLabels, 'label');
      }
      onSuccess();
    } catch {
      onError();
    }
  };
};
