import React, { useEffect } from 'react';
import { LoadingIndicator } from '../../components/LoadingIndicator';
import { App, Button, DatePicker, Form, Select, Space, Switch } from 'antd';
import { API_DATE_FORMAT, SHORT_DATE_FORMAT } from '../../utils/dateFormatUtils';
import dayjs from 'dayjs';
import { useQuery } from '@apollo/client';
import { CloseOutlined, CloudDownloadOutlined, FilePdfOutlined, PrinterOutlined } from '@ant-design/icons';
import { useObjectUrlResource } from '../../hooks/useObjectUrlResource';
import { PdfPreviewModal } from '../../components/PdfPreviewModal';
import { saveAs } from 'file-saver';
import { graphql } from '../../graphql/generated';
import { useForm, useWatch } from 'antd/es/form/Form';

const DAILY_REPORT_QUERY = graphql(`
  query DailyReport($doctorId: ID!) {
    doctor(id: $doctorId) {
      id
      assignedLabs {
        lab {
          id
          name
          identifier
        }
      }
    }
  }
`);

type FormModel = {
  reportDate: dayjs.Dayjs;
  labId: string;
  showParams: boolean;
  sortBy: 'REAL_CREATED_AT' | 'PATIENT_LAST_NAME';
};

export const DailyReport: React.FC<{ doctorId: string }> = ({ doctorId }) => {
  const [form] = useForm<FormModel>();
  const labId = useWatch('labId', form);
  const reportDate = useWatch('reportDate', form);
  const { message } = App.useApp();

  const { data, loading } = useQuery(DAILY_REPORT_QUERY, {
    variables: {
      doctorId: doctorId,
    },
  });

  const {
    objectUrl: pdfUrl,
    blob: pdfBlob,
    fetchAndSet: fetchPdf,
    cleanup: cleanupPdf,
    loading: pdfLoading,
  } = useObjectUrlResource(
    () => message.error('Es ist ein Fehler beim Laden des Dokuments aufgetreten'),
    'application/pdf'
  );

  useEffect(() => {
    if (data?.doctor?.assignedLabs.length) {
      form.setFieldValue('labId', data.doctor.assignedLabs[0].lab.id);
    }
  }, [form, data]);

  const generate = async (values: FormModel) => {
    const url =
      window._env_.API_URL +
      '/rest/daily-report?' +
      new URLSearchParams({
        doctorId: doctorId,
        labId: data?.doctor?.assignedLabs.find(it => it.lab.id === values.labId)?.lab.identifier ?? '',
        reportDate: values.reportDate.format(API_DATE_FORMAT),
        showParams: values.showParams ? 'true' : 'false',
        sortBy: values.sortBy,
      }).toString();

    fetchPdf(url);
  };

  const print = async () => {
    if (!pdfBlob || !window.nativeApi) {
      return;
    }

    try {
      const buffer = await pdfBlob.arrayBuffer();
      await window.nativeApi.print(buffer, 'a4');
      message.success('Dokument wurde an den Drucker gesendet');
    } catch (e) {
      message.error('Beim Drucken ist ein Fehler aufgetreten');
    }
  };

  const download = () => {
    if (!pdfBlob) {
      return;
    }
    saveAs(
      pdfBlob,
      `tageszusammenfassung_${data?.doctor?.assignedLabs.find(it => it.lab.id === labId)?.lab.name}_${reportDate.format(
        API_DATE_FORMAT
      )}.pdf`
    );
  };

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <>
      <Form<FormModel>
        layout="horizontal"
        colon={false}
        labelCol={{ style: { width: '110px' } }}
        labelAlign="left"
        form={form}
        onFinish={generate}
        initialValues={{ reportDate: dayjs(), labId: '', showParams: false, sortBy: 'PATIENT_LAST_NAME' }}
      >
        <Form.Item name="reportDate" label="Tag">
          <DatePicker
            style={{ width: '100%' }}
            format={SHORT_DATE_FORMAT}
            allowClear={false}
            disabledDate={current => current && current.isAfter(dayjs())}
          />
        </Form.Item>
        <Form.Item name="labId" label="Labor" hidden={(data?.doctor?.assignedLabs.length ?? 0) <= 1}>
          <Select
            options={data?.doctor?.assignedLabs.map(it => ({
              label: it.lab.name,
              value: it.lab.id,
            }))}
            popupMatchSelectWidth={false}
            style={{ width: '100%' }}
            allowClear={false}
          />
        </Form.Item>
        <Form.Item name="sortBy" label="Sortierung">
          <Select
            popupMatchSelectWidth={false}
            options={[
              { value: 'REAL_CREATED_AT', label: 'Auftrag Erstellungszeitpunkt' },
              { value: 'PATIENT_LAST_NAME', label: 'Patient Nachname' },
            ]}
            style={{ width: '100%' }}
            allowClear={false}
          />
        </Form.Item>
        <Form.Item name="showParams" label="Mit Parameter">
          <Switch />
        </Form.Item>
        <Button htmlType="submit" icon={<FilePdfOutlined />} style={{ width: '100%' }} disabled={!labId || !reportDate}>
          Erstellen
        </Button>
      </Form>
      <PdfPreviewModal
        title={`Tageszusammenfassung ${
          data?.doctor?.assignedLabs.find(it => it.lab.id === labId)?.lab.name
        } ${reportDate?.format(SHORT_DATE_FORMAT)}`}
        url={pdfUrl}
        loading={pdfLoading}
        footer={
          <Space direction="horizontal">
            <Button hidden={!window.nativeApi} type="primary" icon={<PrinterOutlined />} onClick={print}>
              Drucken
            </Button>
            <Button
              type={!window.nativeApi ? 'primary' : undefined}
              icon={<CloudDownloadOutlined />}
              onClick={download}
            >
              Download
            </Button>
            <Button icon={<CloseOutlined />} onClick={cleanupPdf}>
              Schließen
            </Button>
          </Space>
        }
        onClose={cleanupPdf}
      />
    </>
  );
};
