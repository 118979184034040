import { Insurance, Maybe, PatientData } from '../graphql/generated/graphql.ts';

function append(currentValue: string, component: string, separator: string) {
  let combined = currentValue;
  if (component.length > 0) {
    if (currentValue) combined += separator;
    combined += component;
  }
  return combined;
}

export const patientAddress = (
  patientData:
    | {
        street: string;
        zip: string;
        city: string;
        country: string;
      }
    | null
    | undefined
): string => {
  if (!patientData) {
    return '';
  }

  let result = patientData.street;
  const zipCity = append(patientData.zip, patientData.city, ' ');
  result = append(result, zipCity, ', ');
  if (result.length > 0) {
    result = append(result, patientData.country, ', ');
  }

  return result;
};

export const patientFullName = (patient: { title: string; lastName: string; firstName: string }) => {
  const titleLastName = `${patient.title} ${patient.lastName}`.trim();
  return [titleLastName, patient.firstName].join(', ').trim();
};

export const hasCompleteAddress = (
  patientData:
    | {
        street: string;
        zip: string;
        city: string;
        insuredPerson?: Maybe<{ street: string; zip: string; city: string }>;
      }
    | null
    | undefined
): boolean => {
  const patientHasAddress =
    !!patientData && patientData.street.length > 0 && patientData.zip.length > 0 && patientData.city.length > 0;
  if (patientHasAddress && !!patientData.insuredPerson) {
    const insuredPerson = patientData.insuredPerson;
    return insuredPerson.street.length > 0 && insuredPerson.zip.length > 0 && insuredPerson.city.length > 0;
  }
  return patientHasAddress;
};

export const insuranceName = (
  patientData: Pick<PatientData, 'insuranceCode'>,
  insurances: Pick<Insurance, 'code' | 'shortName'>[]
) =>
  patientData.insuranceCode === ''
    ? 'Privat'
    : (insurances.find(i => i.code === patientData.insuranceCode)?.shortName ?? '');
