import React, { memo, useEffect, useMemo, useRef, useState } from 'react';
import { App, Button, Flex, Input, Select, Table, theme, Tooltip } from 'antd';
import { ClockCircleOutlined, InfoCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import { TestTubeColor } from '../../../components/TestTubeColor';
import {
  billingTypeChar,
  isLgBillingType,
  testTubeColorProperties,
  translateBillingType,
} from '../../../utils/enumHelpers';
import { useDebounce } from 'use-debounce';
import { Markable } from '../../../components/Markable';
import { tableActionCell, tableHoverPointer } from '../../../styles/globalCss';
import { css, cx } from '@emotion/css';
import { SelectedParameter } from '../../../hooks/store/useAppStore';
import {
  BillingType,
  ParameterSelection_RequestableParameterFragment,
  ParameterToAddInput,
} from '../../../graphql/generated/graphql.ts';
import { ColumnType } from 'antd/es/table/interface';
import { AlignType } from 'rc-table/lib/interface';
import { AcuteFilterSwitch } from './parameters/AcuteFilterSwitch.tsx';
import { FaLgFilterSwitch } from './parameters/FaLgFilterSwitch.tsx';
import { useResizeObserver } from 'usehooks-ts';

const { Option } = Select;

const useClassNames = () => {
  const { token } = theme.useToken();

  return useMemo(() => {
    return {
      tableWrapper: css`
        overflow: auto;
        flex-grow: 1;
      `,
      table: css`
        .ant-table-tbody .ant-table-cell {
          display: flex;
          align-items: center;
        }
      `,
      labSelect: css`
        width: auto;
        flex-grow: 1;
        min-width: 130px;
      `,
      searchInput: css`
        width: auto;
        flex-grow: 1;
        min-width: 130px;
      `,
      actionsCol: cx(
        tableActionCell,
        css`
          justify-content: right;
        `
      ),
      billingButton: css`
        width: 100%;
        background: transparent;

        .ant-btn {
          width: 100%;
        }
      `,
      acuteIcon: (disabled: boolean) => css`
        font-size: 16px;
        color: ${disabled ? token.colorTextDisabled : token.colorText};
        cursor: ${disabled ? 'not-allowed' : 'inherit'};
      `,
      ellipsis: css`
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      `,
    };
  }, [token]);
};

const TABLE_HEADER_HEIGHT = 39;

interface ParametersProps {
  params: readonly ParameterSelection_RequestableParameterFragment[];
  selectedParams: SelectedParameter[];
  flipParamNames: boolean;
  onAdd: (paramInput: ParameterToAddInput, chooseBilling: boolean) => void;
  onRemove: (paramId: string) => void;
  onInfo: (param: ParameterSelection_RequestableParameterFragment) => void;
}

const Parameters: React.FC<ParametersProps> = ({ params, selectedParams, flipParamNames, onAdd, onRemove, onInfo }) => {
  const [search, setSearch] = useState<string | null>(null);
  const [debouncedSearch] = useDebounce(search, 250);
  const [filteredParams, setFilteredParams] = useState<ParameterSelection_RequestableParameterFragment[]>([]);
  const [selectedLabId, setSelectedLabId] = useState<string | null>(null);
  const [acuteOnly, setAcuteOnly] = useState(false);
  const [lgOnly, setLgOnly] = useState(false);
  const { message } = App.useApp();
  const { token } = theme.useToken();
  const classNames = useClassNames();

  const tableWrapperRef = useRef<HTMLDivElement>(null);
  const { height = 0 } = useResizeObserver({
    ref: tableWrapperRef,
    box: 'border-box',
  });

  useEffect(() => {
    setFilteredParams(
      params.filter(param => {
        if (debouncedSearch && debouncedSearch.length) {
          const match = debouncedSearch
            .toLowerCase()
            .split(' ')
            .some(
              token =>
                param.shortName.toLowerCase().includes(token) ||
                param.longName.toLowerCase().includes(token) ||
                param.synonyms.some(it => it.toLowerCase().includes(token))
            );
          if (!match) {
            return false;
          }
        }
        if (selectedLabId) {
          const match = param.lab.id === selectedLabId;
          if (!match) {
            return false;
          }
        }
        if (acuteOnly) {
          if (!param.acute) {
            return false;
          }
        }
        if (lgOnly) {
          if (!param.billingInfos.some(bi => isLgBillingType(bi.billingType))) {
            return false;
          }
        }
        return true;
      }) ?? []
    );
  }, [debouncedSearch, selectedLabId, acuteOnly, lgOnly, params]);

  const handleClick = (parameter: ParameterSelection_RequestableParameterFragment, chooseBilling: boolean) => {
    if (isAddDisabled(parameter.id)) {
      message.warning('Der Parameter ist nicht mit den bereits ausgewählten Parametern kompatibel');
      return;
    }

    if (selectedParams.map(sp => sp.id).includes(parameter.id)) {
      onRemove(parameter.id);
      return;
    }
    onAdd({ parameterId: parameter.id, preferredBillingType: null, preferredDiagnoseIds: [] }, chooseBilling);
  };

  const isAddDisabled = (id: string) => selectedParams.flatMap(sp => sp.withoutParameterIds).includes(id);

  const availableLabs = useMemo(
    () =>
      params.reduce<ParameterSelection_RequestableParameterFragment['lab'][]>(
        (labs, param) => (labs.some(l => l.id === param.lab.id) ? labs : [...labs, param.lab]),
        []
      ),
    [params]
  );

  const hasAcuteParams = useMemo(() => params.some(it => it.acute), [params]);
  const hasLgParams = useMemo(
    () => params.some(it => it.billingInfos.some(bi => isLgBillingType(bi.billingType))),
    [params]
  );

  const shortNameCol: ColumnType<ParameterSelection_RequestableParameterFragment> = {
    title: 'Kurzname',
    dataIndex: 'shortName',
    key: 'shortName',
    ellipsis: true,
    defaultSortOrder: flipParamNames ? undefined : 'ascend',
    width: 110,
    sorter: (a, b) => a.shortName.localeCompare(b.shortName),
    render: (value, record) => {
      const disabled = isAddDisabled(record.id);
      return (
        <div className={classNames.ellipsis}>
          <span
            title={value}
            style={{
              color: disabled ? token.colorTextDisabled : token.colorText,
              fontWeight: flipParamNames ? 'normal' : 500,
            }}
          >
            <Markable tokens={debouncedSearch ?? ''}>{value}</Markable>
          </span>
        </div>
      );
    },
  };

  const longNameCol: ColumnType<ParameterSelection_RequestableParameterFragment> = {
    title: 'Langbezeichnung',
    dataIndex: 'longName',
    key: 'longName',
    ellipsis: true,
    defaultSortOrder: flipParamNames ? 'ascend' : undefined,
    width: 160,
    sorter: (a, b) => a.longName.localeCompare(b.longName),
    render: (value, record) => {
      const disabled = isAddDisabled(record.id);
      return (
        <div className={classNames.ellipsis}>
          <span
            title={value}
            style={{
              color: disabled ? token.colorTextDisabled : token.colorText,
              fontWeight: flipParamNames ? 500 : 'normal',
            }}
          >
            <Markable tokens={debouncedSearch ?? ''}>{value}</Markable>
          </span>
        </div>
      );
    },
  };

  const columns: ColumnsType<ParameterSelection_RequestableParameterFragment> = [
    ...(flipParamNames ? [longNameCol, shortNameCol] : [shortNameCol, longNameCol]),
    ...(hasAcuteParams
      ? [
          {
            title: 'Akut',
            dataIndex: 'acute',
            key: 'acute',
            width: 70,
            align: 'center' as AlignType,
            sorter: (
              a: ParameterSelection_RequestableParameterFragment,
              b: ParameterSelection_RequestableParameterFragment
            ) => Number(a.acute) - Number(b.acute),
            render: (value: boolean, record: ParameterSelection_RequestableParameterFragment) => {
              const disabled = isAddDisabled(record.id);
              return value ? <ClockCircleOutlined className={classNames.acuteIcon(disabled)} /> : '';
            },
          },
        ]
      : []),
    ...(availableLabs.length > 1
      ? [
          {
            title: 'Labor',
            dataIndex: ['lab', 'shortName'],
            key: 'lab.shortName',
            ellipsis: true,
            width: 80,
            sorter: (
              a: ParameterSelection_RequestableParameterFragment,
              b: ParameterSelection_RequestableParameterFragment
            ) => a.lab.shortName.localeCompare(b.lab.shortName),
            render: (value: string, record: ParameterSelection_RequestableParameterFragment) => {
              const disabled = isAddDisabled(record.id);
              return (
                <span
                  style={{
                    color: disabled ? token.colorTextDisabled : token.colorText,
                  }}
                >
                  {value}
                </span>
              );
            },
          },
        ]
      : []),
    {
      title: 'Verrechnung',
      dataIndex: 'billingTypes',
      key: 'billingTypes',
      width: 110,
      render: (_, record) => {
        const billingTypes = record.billingInfos.reduce<BillingType[]>(
          (billingTypes, billingInfo) =>
            billingTypes.some(bt => bt === billingInfo.billingType)
              ? billingTypes
              : [...billingTypes, billingInfo.billingType],
          []
        );

        const billingChars = billingTypes
          .filter(it => !isLgBillingType(it) && it !== BillingType.DEFINE_LATER)
          .map(bt => billingTypeChar(bt))
          .join('');

        const lgBillingChars = billingTypes
          .filter(it => isLgBillingType(it) && it !== BillingType.DEFINE_LATER)
          .map(bt => billingTypeChar(bt))
          .join('');

        return (
          <Button
            title={billingTypes.map(it => translateBillingType(it)).join(', ')}
            size="small"
            type="default"
            disabled={isAddDisabled(record.id)}
            className={classNames.billingButton}
            onClick={e => {
              e.stopPropagation();
              handleClick(record, true);
            }}
          >
            {billingChars}
            {lgBillingChars.length ? ' LG:' + lgBillingChars : ''}
          </Button>
        );
      },
    },
    {
      title: 'Proben',
      dataIndex: 'testTubes',
      width: 90,
      key: 'testTubes',
      render: (_, record) => (
        <Flex gap={4}>
          {record.specimens
            .flatMap(it => it.testTubes)
            .map((tt, i) => (
              <div key={tt.id + '_' + i} title={tt.name}>
                <TestTubeColor withText={false} properties={testTubeColorProperties(tt.color)} />
              </div>
            ))}
        </Flex>
      ),
    },
    {
      title: '',
      key: 'actions',
      fixed: 'right',
      width: 50,
      className: classNames.actionsCol,
      render: (_, record) => (
        <Button
          icon={<InfoCircleOutlined />}
          type="text"
          onClick={e => {
            e.stopPropagation();
            onInfo(record);
          }}
        />
      ),
    },
  ];

  return (
    <>
      <Flex align="center" wrap gap={token.paddingSM}>
        <Input
          className={classNames.searchInput}
          allowClear
          placeholder="Suche nach Parameter"
          value={search ?? ''}
          onChange={e => setSearch(e.target.value)}
          prefix={<SearchOutlined />}
          suffix={
            <Tooltip title="Suche nach Kurzname, Langbezeichnung und Synonyme">
              <InfoCircleOutlined />
            </Tooltip>
          }
        />
        {availableLabs.length > 1 && (
          <Select
            allowClear
            onChange={labId => setSelectedLabId(labId)}
            value={selectedLabId}
            placeholder="Labors filtern"
            className={classNames.labSelect}
          >
            {params
              .reduce<ParameterSelection_RequestableParameterFragment['lab'][]>(
                (labs, param) => (labs.some(l => l.id === param.lab.id) ? labs : [...labs, param.lab]),
                []
              )
              .map(lab => (
                <Option key={lab.id} value={lab.id}>
                  {lab.name}
                </Option>
              ))}
          </Select>
        )}
        <Flex gap={token.paddingSM}>
          {hasAcuteParams && <AcuteFilterSwitch onChange={setAcuteOnly} />}
          {hasLgParams && <FaLgFilterSwitch onChange={setLgOnly} />}
        </Flex>
      </Flex>

      <p />
      <div ref={tableWrapperRef} className={classNames.tableWrapper}>
        <Table<ParameterSelection_RequestableParameterFragment>
          virtual
          scroll={{ y: height - TABLE_HEADER_HEIGHT }}
          showSorterTooltip={false}
          className={classNames.table}
          rowClassName={tableHoverPointer}
          size="small"
          rowKey={row => row.id}
          onRow={record => {
            return {
              onClick: e => {
                e.preventDefault();
                handleClick(record, false);
              },
            };
          }}
          rowSelection={{
            columnWidth: 24,
            type: 'checkbox',
            hideSelectAll: true,
            selectedRowKeys: selectedParams.map(p => p.id),
            onSelect: (record: ParameterSelection_RequestableParameterFragment, selected: boolean) => {
              if (isAddDisabled(record.id)) {
                message.warning('Der Parameter ist nicht mit den bereits ausgewählten Parametern kompatibel');
                return;
              }
              selected
                ? onAdd({ parameterId: record.id, preferredBillingType: null, preferredDiagnoseIds: [] }, false)
                : onRemove(record.id);
            },
          }}
          pagination={false}
          dataSource={filteredParams}
          columns={columns}
        />
      </div>
    </>
  );
};

export const MemoParameters = memo(Parameters);
