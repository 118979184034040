import React, { useEffect } from 'react';
import { App, Card, Result, theme } from 'antd';
import Icon from '@ant-design/icons';
import { AddPatientSvg } from '../components/icons/AddPatientSvg';
import { ImportPatientSvg } from '../components/icons/ImportPatientSvg';
import { SearchPatientSvg } from '../components/icons/SearchPatientSvg';
import { useNavigate, useSearchParams } from 'react-router';
import { aisImport } from '../utils/aisImport';
import { css } from '@emotion/css';
import { useDoctorHasAis } from '../hooks/useDoctorHasAis';
import { useAppStore } from '../hooks/store/useAppStore';
import { useShallow } from 'zustand/react/shallow';
import { hasFrontDeskRole, hasSomeRole, Role } from '../utils/user';
import { useAuth } from 'react-oidc-context';
import { useCurrentContextStore } from '../hooks/store/useCurrentContextStore.ts';

const { useToken } = theme;

export const NewRequest: React.FC = () => {
  const { token } = useToken();
  const { notification, message } = App.useApp();
  const navigate = useNavigate();
  const auth = useAuth();
  const [searchParams] = useSearchParams();

  const { setPatientData, setDiagnose, setAdditionalData, setAdditionalDataInvalidFields, setAisImportResult } =
    useAppStore(
      useShallow(state => ({
        setPatientData: state.setPatientData,
        setDiagnose: state.setDiagnose,
        setAdditionalData: state.setAdditionalData,
        setAdditionalDataInvalidFields: state.setAdditionalDataInvalidFields,
        setAisImportResult: state.setAisImportResult,
      }))
    );
  const { currentDoctorId, setCurrentDoctorId, primaryDoctorId } = useCurrentContextStore();
  const hasAis = useDoctorHasAis(hasFrontDeskRole(auth.user) ? primaryDoctorId : currentDoctorId);

  const styles = {
    container: css`
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      padding: ${token.paddingMD}px;
    `,
    card: css`
      margin-right: ${token.paddingMD}px;
      width: 300px;

      &:last-child {
        margin-right: 0;
      }

      .ant-card-body {
        text-align: center;
      }
    `,
    icon: css`
      width: 100px;
      display: inline-block; /* safari fix */

      .colored {
        fill: ${token.colorPrimary};
      }
    `,
  };

  const importFromAis = async () => {
    if (!window.nativeApi) {
      notification.info({
        key: 'medcom-desktop-warning',
        duration: 5,
        message: 'Medcom Desktop nicht installiert',
        description:
          'Sie müssen Medcom Desktop auf Ihrem Arbeitsplatz installieren, um mit Ihrem AIS kommunizieren zu können',
        placement: 'top',
      });
      return;
    }

    try {
      const result = await aisImport(
        auth.user?.access_token ?? '',
        hasFrontDeskRole(auth.user) ? primaryDoctorId : currentDoctorId
      );

      setAisImportResult(result);
      setPatientData(result.patientData);
      setDiagnose(result.diagnose);
      setAdditionalData(result.additionalData);
      setAdditionalDataInvalidFields(result.invalidAdditionalDataFields);

      if (result.switchToDoctorId) {
        setCurrentDoctorId(result.switchToDoctorId);
      }

      if (result.invalidPatientFields.length > 0) {
        navigate('/anforderung/patient-erfassung', {
          state: { invalidFields: result.invalidPatientFields, validateInitial: true },
        });
      } else {
        navigate('/anforderung/parameterauswahl');
      }
    } catch (e) {
      if (e instanceof Error) {
        message.error(e.message);
        return;
      }
      if (e instanceof String) {
        message.error(e);
      }
    }
  };

  useEffect(() => {
    if (searchParams.has('doAisImport')) {
      importFromAis();
    }
  }, [searchParams, importFromAis]);

  if (
    !hasSomeRole([Role.ROLE_LR_MEDCOM, Role.ROLE_LR_LAB_ADMIN, Role.ROLE_LR_FRONT_DESK, Role.ROLE_LR_USER], auth.user)
  ) {
    return (
      <div className={styles.container}>
        <Result
          status={'warning'}
          title="Neue Anforderung nicht möglich"
          subTitle="Sie haben nicht die benötigten Rechte für die Erstellung von neuen Anforderungen"
        />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      {hasAis && (
        <Card className={styles.card} hoverable title="Patient aus AIS" onClick={importFromAis}>
          <Icon className={styles.icon} component={ImportPatientSvg} />
        </Card>
      )}
      <Card
        className={styles.card}
        hoverable
        title="Patient erfassen"
        onClick={() => navigate('/anforderung/patient-erfassung')}
      >
        <Icon className={styles.icon} component={AddPatientSvg} />
      </Card>
      <Card className={styles.card} hoverable title="Patient suchen" onClick={() => navigate('/patient-suche')}>
        <Icon className={styles.icon} component={SearchPatientSvg} />
      </Card>
    </div>
  );
};
