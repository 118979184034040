import React from 'react';
import {
  Button,
  Card,
  Checkbox,
  Col,
  Dropdown,
  Flex,
  Form as AntForm,
  Form,
  FormInstance,
  Image,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Space,
  Tabs,
  theme,
  Transfer,
  Typography,
} from 'antd';
import {
  translateAisExportType,
  translateAisImportType,
  translateBillingPreference,
  translateDoctorSalutation,
  translateTestTubeSupplier,
} from '../../../utils/enumHelpers';
import {
  BarcodeOutlined,
  DeleteOutlined,
  InfoCircleOutlined,
  MailOutlined,
  PhoneOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';
import { useWatch } from 'antd/es/form/Form';
import { FieldData } from 'rc-field-form/lib/interface';
import {
  AisExportType,
  AisImportType,
  AssignedLabInput,
  BillingPreference,
  DoctorForm_InsuranceFragment,
  DoctorForm_SpecialRateFragment,
  DoctorInput,
  DoctorSalutation,
  TestTubeSupplier,
} from '../../../graphql/generated/graphql.ts';
import { FragmentType, getFragmentData, graphql } from '../../../graphql/generated';
import { serverSideEmailRule } from '../../../utils/helpers.ts';
import { highlightTags, theme as codemirrorTheme, zplDoctorLabel } from '../../../styles/codemirror.ts';
import ReactCodeMirror from '@uiw/react-codemirror';
import { useLabelary } from '../../../hooks/useLabelary.ts';

const { Option } = Select;

export const LabFragment = graphql(`
  fragment DoctorForm_Lab on Lab {
    id
    name
  }
`);

export const ThemeFragment = graphql(`
  fragment DoctorForm_Theme on Theme {
    id
    name
  }
`);

export const CatalogFragment = graphql(`
  fragment DoctorForm_Catalog on Catalog {
    id
    name
    lab {
      id
      shortName
    }
  }
`);

export const InsuranceFragment = graphql(`
  fragment DoctorForm_Insurance on Insurance {
    id
    name
    shortName
    code
  }
`);

export const SpecialRateFragment = graphql(`
  fragment DoctorForm_SpecialRate on SpecialRate {
    id
    name
    shortName
    lab {
      id
      shortName
    }
  }
`);

export const DoctorForm: React.FC<{
  allMeAddresses: string[];
  form: FormInstance<DoctorInput>;
  initialValues?: DoctorInput;
  onFinish: (values: DoctorInput) => void;
  onFieldsChange: (changedFields: FieldData[], allFields: FieldData[]) => void;
  labFragments: FragmentType<typeof LabFragment>[];
  catalogFragments: FragmentType<typeof CatalogFragment>[];
  insuranceFragments: FragmentType<typeof InsuranceFragment>[];
  specialRateFragments: FragmentType<typeof SpecialRateFragment>[];
  themeFragments: FragmentType<typeof ThemeFragment>[];
}> = ({
  allMeAddresses,
  form,
  initialValues,
  onFinish,
  onFieldsChange,
  labFragments,
  catalogFragments,
  insuranceFragments,
  specialRateFragments,
  themeFragments,
}) => {
  const assignedLabs = useWatch<AssignedLabInput[]>('assignedLabs', form);
  const labs = getFragmentData(LabFragment, labFragments);
  const themes = getFragmentData(ThemeFragment, themeFragments);
  const catalogs = getFragmentData(CatalogFragment, catalogFragments);
  const insurances = getFragmentData(InsuranceFragment, insuranceFragments);
  const specialRates = getFragmentData(SpecialRateFragment, specialRateFragments);

  const { token } = theme.useToken();
  const cmTheme = codemirrorTheme(token.colorBorderSecondary, token.colorPrimaryActive);
  const tooltip = {
    styles: { body: { width: '400px' } },
    overlay: (
      <div>
        Mögliche Variablen:
        <br />
        %PATIENT% - Patient Name
        <br />
        %PATIENTDOB% - Patient Geburtstag
        <br />
        %PATIENTAISID% - Patient externe AIS-ID
        <br />
        %SVNR% - Versicherungsnummer
        <br />
        %DOCTOR% - Zuweiser Name
        <br />
        %DOCTORID% - Zuweiser Kundennummer
        <br />
        %BARCODE% - Auftragsnummer unformatiert
        <br />
        %ID% - UUID des Auftrags
        <br />
        %LAB% - Labor Kurzbezeichnung
      </div>
    ),
    icon: <InfoCircleOutlined />,
  };

  const [labelaryUrl, fetchLabelary, cleanupLabelary] = useLabelary();

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={onFinish}
      onFieldsChange={onFieldsChange}
      initialValues={initialValues}
    >
      <Tabs
        defaultActiveKey="stammdaten"
        size="small"
        items={[
          {
            key: 'stammdaten',
            label: 'Stammdaten',
            forceRender: true,
            children: (
              <>
                <Row gutter={[16, 0]}>
                  <Col span={8}>
                    <Form.Item
                      name="salutation"
                      label="Anrede"
                      rules={[{ type: 'enum', enum: Object.keys(DoctorSalutation), required: true }]}
                    >
                      <Select autoFocus>
                        {Object.keys(DoctorSalutation).map(s => {
                          return (
                            <Option key={s} value={s}>
                              {translateDoctorSalutation(s)}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="preTitle" label="Titel vorangestellt">
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="postTitle" label="Titel nachgestellt">
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[16, 0]}>
                  <Col span={12}>
                    <Form.Item name="firstName" label="Vorname">
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="lastName"
                      label="Nachname bzw. Firma"
                      tooltip="Relevant für z.B. HL7 und Dokumente"
                      rules={[{ type: 'string', required: true, whitespace: true }]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[16, 0]}>
                  <Col span={12}>
                    <Form.Item
                      name="name"
                      label="Anzeigename"
                      tooltip="Wird im UI angezeigt"
                      rules={[{ type: 'string', required: true, whitespace: true }]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="specialist" label="Facharzt">
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[16, 0]}>
                  <Col span={12}>
                    <Form.Item
                      name="meAddress"
                      label="ME-Adresse"
                      rules={[
                        {
                          validator: (_, value) => {
                            if (value && allMeAddresses.some(it => it === value)) {
                              return Promise.reject('Diese ME-Adresse ist bereits vergeben');
                            }
                            return Promise.resolve();
                          },
                          warningOnly: true,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="hvNumber" label="Hauptverbandsnummer">
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[16, 0]}>
                  <Col span={8}>
                    <Form.Item
                      name="street"
                      label="Straße inkl. Hausnummer"
                      rules={[{ type: 'string', required: true, whitespace: true }]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="zip" label="PLZ" rules={[{ type: 'string', required: true, whitespace: true }]}>
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="city" label="Ort" rules={[{ type: 'string', required: true, whitespace: true }]}>
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[16, 0]}>
                  <Col span={12}>
                    <Form.Item name="email" label="E-Mail" rules={[serverSideEmailRule]} validateDebounce={200}>
                      <Input addonBefore={<MailOutlined />} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="phone" label="Telefon">
                      <Input addonBefore={<PhoneOutlined />} />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item name="disabled" valuePropName="checked" noStyle>
                  <Checkbox>Deaktiviert</Checkbox>
                </Form.Item>
              </>
            ),
          },
          {
            key: 'billing',
            label: 'Kataloge & Verrechnung',
            forceRender: true,
            children: (
              <>
                <Form.Item name="catalogIds" label="Parameterkataloge">
                  <Select
                    showSearch
                    optionFilterProp="search"
                    allowClear
                    mode="multiple"
                    placeholder="Bitte Parameterkataloge auswählen"
                  >
                    {[...catalogs]
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .map(catalog => (
                        <Option
                          key={catalog.id}
                          value={catalog.id}
                          search={`${catalog.name} - ${catalog.lab.shortName}`}
                        >
                          {catalog.name} - {catalog.lab.shortName}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
                <Row gutter={[16, 0]}>
                  <Col span={12}>
                    <Form.Item /* fixes spacing*/>
                      <Space direction="vertical">
                        <Form.Item name="patientBilling" valuePropName="checked" noStyle>
                          <Checkbox>Privat an Patient</Checkbox>
                        </Form.Item>
                        <Form.Item name="doctorBilling" valuePropName="checked" noStyle>
                          <Checkbox>Privat an Zuweiser</Checkbox>
                        </Form.Item>
                      </Space>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item /* fixes spacing*/>
                      <Space direction="vertical">
                        <Form.Item name="showPrices" valuePropName="checked" noStyle>
                          <Checkbox>Preise anzeigen</Checkbox>
                        </Form.Item>
                        <Form.Item name="showPriceSuggestion" valuePropName="checked" noStyle>
                          <Checkbox>Preisvorschlag anzeigen</Checkbox>
                        </Form.Item>
                      </Space>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[16, 0]}>
                  <Col span={12}>
                    <Form.Item
                      name="billingPreference"
                      label="Präferenz Privatverrechnung"
                      rules={[{ type: 'enum', enum: Object.keys(BillingPreference) }]}
                    >
                      <Select>
                        {Object.keys(BillingPreference).map(bp => {
                          return (
                            <Option key={bp} value={bp}>
                              {translateBillingPreference(bp)}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Space direction="vertical">
                      <Form.Item name="autoDiagnose" valuePropName="checked" noStyle>
                        <Checkbox>
                          Diagnose automatisch übernehmen <br />
                          <Typography.Text type="secondary">
                            <small>Wenn nur eine Diagnose verfügbar</small>
                          </Typography.Text>
                        </Checkbox>
                      </Form.Item>
                      <Form.Item name="autoSpecialRate" valuePropName="checked" noStyle>
                        <Checkbox>
                          Sondertarif automatisch übernehmen
                          <br />
                          <Typography.Text type="secondary">
                            <small>Wenn nur ein Sondertarif verfügbar</small>
                          </Typography.Text>
                        </Checkbox>
                      </Form.Item>
                      <Form.Item name="preferSpecialRate" valuePropName="checked" noStyle>
                        <Checkbox>Sondertarif hat Priorität ggü. Kasse</Checkbox>
                      </Form.Item>
                    </Space>
                  </Col>
                </Row>
                <Form.Item name="insuranceIds" label="Krankenkassen" valuePropName="targetKeys">
                  <Transfer<DoctorForm_InsuranceFragment>
                    dataSource={[...insurances].sort((a, b) => a.shortName.localeCompare(b.shortName))}
                    titles={['Nicht anforderbar', 'Anforderbar']}
                    render={insurance => (
                      <>
                        {insurance.shortName} <Typography.Text type="secondary">{insurance.code}</Typography.Text>
                      </>
                    )}
                    showSearch
                    filterOption={(inputValue, insurance) =>
                      insurance.name.toLowerCase().includes(inputValue.toLowerCase()) ||
                      insurance.shortName.toLowerCase().includes(inputValue.toLowerCase())
                    }
                    listStyle={{ height: '300px', width: '100%', overflow: 'hidden' }}
                    rowKey={insurance => insurance.id}
                  />
                </Form.Item>
                <Form.Item name="specialRateIds" label="Sondertarife" valuePropName="targetKeys">
                  <Transfer<DoctorForm_SpecialRateFragment>
                    dataSource={[...specialRates].sort((a, b) => a.shortName.localeCompare(b.shortName))}
                    titles={['Nicht anforderbar', 'Anforderbar']}
                    render={sr => (
                      <>
                        {sr.shortName} <Typography.Text type="secondary">{sr.lab.shortName}</Typography.Text>
                      </>
                    )}
                    showSearch
                    filterOption={(inputValue, specialRate) =>
                      specialRate.name.toLowerCase().includes(inputValue.toLowerCase()) ||
                      specialRate.shortName.toLowerCase().includes(inputValue.toLowerCase())
                    }
                    listStyle={{ height: '300px', width: '100%', overflow: 'hidden' }}
                    rowKey={sr => sr.id}
                  />
                </Form.Item>
              </>
            ),
          },
          {
            key: 'lg',
            label: 'LG Verrechnung',
            forceRender: true,
            children: (
              <>
                <Form.Item name="lgDoctorBilling" valuePropName="checked">
                  <Checkbox>Privat an Zuweiser</Checkbox>
                </Form.Item>
                <Form.Item name="lgInsuranceIds" label="Krankenkassen" valuePropName="targetKeys">
                  <Transfer<DoctorForm_InsuranceFragment>
                    dataSource={[...insurances].sort((a, b) => a.shortName.localeCompare(b.shortName))}
                    titles={['Nicht anforderbar', 'Anforderbar']}
                    render={insurance => (
                      <>
                        {insurance.shortName} <Typography.Text type="secondary">{insurance.code}</Typography.Text>
                      </>
                    )}
                    showSearch
                    filterOption={(inputValue, insurance) =>
                      insurance.name.toLowerCase().includes(inputValue.toLowerCase()) ||
                      insurance.shortName.toLowerCase().includes(inputValue.toLowerCase())
                    }
                    listStyle={{ height: '300px', width: '100%', overflow: 'hidden' }}
                    rowKey={insurance => insurance.id}
                  />
                </Form.Item>
              </>
            ),
          },
          {
            key: 'labors',
            label: 'Labors',
            forceRender: true,
            children: (
              <Form.List
                name="assignedLabs"
                rules={[
                  {
                    validator: (_, values) => {
                      if (!values || values.length < 1) {
                        return Promise.reject('Mind. ein Labor angeben');
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                {(fields, { add, remove }, { errors }) => (
                  <Space direction="vertical" style={{ width: '100%' }}>
                    <Dropdown
                      menu={{
                        items: labs.map(lab => ({
                          key: lab.id,
                          label: lab.name,
                          disabled: assignedLabs?.map(al => al.labId)?.includes(lab.id) ?? false,
                          onClick: () =>
                            add({
                              labId: lab.id,
                              customerId: '',
                              supplier: TestTubeSupplier.VACUETTE,
                              runningNumber: 0,
                              overrideRunningNumber: false,
                            }),
                        })),
                      }}
                      placement="bottomLeft"
                      trigger={['click']}
                    >
                      <Button icon={<PlusCircleOutlined />}>Labor hinzufügen</Button>
                    </Dropdown>
                    {fields.map(({ key, name, ...restField }, index) => (
                      <Card
                        size="small"
                        title={
                          labs.find(l => l.id === (assignedLabs ?? [])[index]?.labId)?.name ?? `Labor ${index + 1}`
                        }
                        extra={
                          <Button
                            danger
                            size="small"
                            icon={<DeleteOutlined />}
                            type="text"
                            onClick={() => remove(index)}
                          />
                        }
                        style={{ width: '100%' }}
                        key={key}
                      >
                        <Row gutter={[16, 0]}>
                          <Col span={8}>
                            <Form.Item
                              {...restField}
                              name={[name, 'runningNumber']}
                              rules={[{ type: 'integer', required: true, min: 0, message: 'Bitte Zähler angeben' }]}
                              label="Laufende Auftragsnummer"
                            >
                              <InputNumber
                                style={{ width: '100%' }}
                                inputMode="numeric"
                                precision={0}
                                min={0}
                                step={1}
                                disabled={!(assignedLabs ?? [])[index]?.overrideRunningNumber}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={8}>
                            <Form.Item {...restField} name={[name, 'customerId']} label="Kundennummer">
                              <Input />
                            </Form.Item>
                          </Col>
                          <Col span={8}>
                            <Form.Item
                              {...restField}
                              name={[name, 'supplier']}
                              label="Lieferant"
                              rules={[{ type: 'enum', enum: Object.keys(TestTubeSupplier) }]}
                            >
                              <Select>
                                {Object.keys(TestTubeSupplier).map(supplier => (
                                  <Option key={supplier} value={supplier}>
                                    {translateTestTubeSupplier(supplier)}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                        </Row>
                        <Form.Item {...restField} name={[name, 'overrideRunningNumber']} valuePropName="checked">
                          <Checkbox>Laufende Auftragsnummer überschreiben</Checkbox>
                        </Form.Item>
                      </Card>
                    ))}
                    <Form.ErrorList errors={errors} />
                  </Space>
                )}
              </Form.List>
            ),
          },
          {
            key: 'io',
            label: 'I/O',
            forceRender: true,
            children: (
              <>
                <Row gutter={[16, 0]}>
                  <Col span={8}>
                    <Form.Item name="hasAis" label="AIS" valuePropName="checked">
                      <Checkbox>AIS aktiviert</Checkbox>
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="aisImportType"
                      label="AIS-Import-Typ"
                      rules={[{ type: 'enum', enum: Object.keys(AisImportType) }]}
                    >
                      <Select>
                        {Object.keys(AisImportType).map(type => (
                          <Option key={type} value={type}>
                            {translateAisImportType(type)}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="aisImportDoctorId"
                      label="AIS-Import Multi-Seat"
                      tooltip="Die mit diesem Feature verlinkten Zuweiser sollten alle die gleiche AIS-Schnittstelle verwenden, um Inkonsistenzen beim Lesen der Import-Datei zu vermeiden"
                    >
                      <Input placeholder="X-Arzt" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[16, 0]}>
                  <Col span={8}>
                    <Form.Item name="printTransferDocument" label="Überweisungsschein" valuePropName="checked">
                      <Checkbox>Beim Senden drucken</Checkbox>
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="aisExportTypes" label="AIS-Export-Typen">
                      <Select mode="multiple" showSearch={false} placeholder="Bitte wählen...">
                        {Object.keys(AisExportType).map(type => (
                          <Option key={type} value={type}>
                            {translateAisExportType(type)}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="addEmptyLabels"
                      label="Zusätzliche Leeretiketten"
                      tooltip="Anzahl der zusätzlichen Leeretiketten, die pro Anforderung automatisch gedruckt werden"
                      rules={[
                        {
                          type: 'integer',
                          required: true,
                          min: 0,
                          max: 15,
                          message: 'Bitte Anzahl zw. 0 und 15 angeben',
                        },
                      ]}
                    >
                      <InputNumber
                        style={{ width: '100%' }}
                        inputMode="numeric"
                        precision={0}
                        max={15}
                        min={0}
                        step={1}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[16, 0]}>
                  <Col span={16}>
                    <Form.Item
                      name="zplLabelTemplate"
                      label={
                        <div>
                          <span>ZPL-Etiketten-Vorlage</span>{' '}
                          <Button
                            size="small"
                            icon={<BarcodeOutlined />}
                            onClick={() =>
                              fetchLabelary(
                                form.getFieldValue('zplLabelWidth'),
                                form.getFieldValue('zplLabelHeight'),
                                form.getFieldValue('zplLabelTemplate')
                              )
                            }
                          >
                            Vorschau
                          </Button>
                        </div>
                      }
                      tooltip={tooltip}
                    >
                      <ReactCodeMirror
                        width="100%"
                        height="300px"
                        basicSetup={{ lineNumbers: true }}
                        extensions={[zplDoctorLabel, highlightTags]}
                        theme={cmTheme}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="addZplLabels"
                      label="Zusätzliche Zuweiser-Etiketten"
                      tooltip="Anzahl der zusätzlichen Zuweiser-Etiketten, die pro Anforderung automatisch gedruckt werden"
                      rules={[
                        {
                          type: 'integer',
                          required: true,
                          min: 0,
                          max: 15,
                          message: 'Bitte Anzahl zw. 0 und 15 angeben',
                        },
                      ]}
                    >
                      <InputNumber
                        style={{ width: '100%' }}
                        inputMode="numeric"
                        precision={0}
                        max={15}
                        min={0}
                        step={1}
                      />
                    </Form.Item>
                    <Form.Item
                      name="zplLabelWidth"
                      label="Breite ZPL-Etikett in mm für Vorschau"
                      rules={[{ type: 'integer', required: true, min: 10, max: 100, message: 'Bitte Zahl angeben' }]}
                    >
                      <InputNumber inputMode="numeric" required min={10} max={100} step={1} style={{ width: '100%' }} />
                    </Form.Item>
                    <Form.Item
                      name="zplLabelHeight"
                      label="Höhe ZPL-Etikett in mm für Vorschau"
                      rules={[{ type: 'integer', required: true, min: 10, max: 100, message: 'Bitte Zahl angeben' }]}
                    >
                      <InputNumber inputMode="numeric" required min={10} max={100} step={1} style={{ width: '100%' }} />
                    </Form.Item>
                  </Col>
                </Row>
              </>
            ),
          },
          {
            key: 'theme',
            label: 'Theme',
            forceRender: true,
            children: (
              <Row gutter={[16, 0]}>
                <Col span={8}>
                  <Form.Item name="themeId" label="Theme">
                    <Select
                      placeholder="Theme zuweisen"
                      style={{ width: '300px' }}
                      allowClear
                      showSearch
                      optionFilterProp="search"
                    >
                      {themes.map(it => {
                        return (
                          <Option key={it.id} value={it.id} search={it.name}>
                            {it.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="flipParamNames"
                    valuePropName="checked"
                    label="Parameter-Namen"
                    tooltip="Kurzname - Langbezeichnung bzw. Langbezeichnung - Kurzname"
                  >
                    <Checkbox>umdrehen</Checkbox>
                  </Form.Item>
                </Col>
              </Row>
            ),
          },
          {
            key: 'comment',
            label: 'Anmerkungen',
            forceRender: true,
            children: (
              <Form.Item name="comment" label="Interne Anmerkung">
                <Input.TextArea rows={10} />
              </Form.Item>
            ),
          },
          {
            key: 'features',
            label: 'Features',
            forceRender: true,
            children: (
              <Flex vertical gap={10}>
                <AntForm.Item name="csvImportEnabled" valuePropName="checked" noStyle>
                  <Checkbox>CSV-Import</Checkbox>
                </AntForm.Item>
                <AntForm.Item name="ecardEnabled" valuePropName="checked" noStyle>
                  <Checkbox>ecard-Integration</Checkbox>
                </AntForm.Item>
                <AntForm.Item name="overrideRequestEnabled" valuePropName="checked" noStyle>
                  <Checkbox>Offene Anforderung überschreiben</Checkbox>
                </AntForm.Item>
              </Flex>
            ),
          },
        ]}
      />
      {/* hack for enter button support */}
      <Button htmlType="submit" hidden />
      <Modal
        open={!!labelaryUrl}
        onCancel={() => cleanupLabelary()}
        onOk={() => cleanupLabelary()}
        cancelButtonProps={{ hidden: true }}
      >
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Image src={labelaryUrl} style={{ maxWidth: '100%', border: '1px solid black' }} preview={false} />
        </div>
      </Modal>
    </Form>
  );
};
