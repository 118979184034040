import React from 'react';
import { Flex, Progress, Typography } from 'antd';
import { css } from '@emotion/css';
import { RequestStatusBadge } from './RequestStatusBadge';
import { RequestStatus } from '../graphql/generated/graphql.ts';

type Request = {
  orders: Array<{ receivedAt?: string | null }>;
  status: RequestStatus;
};

export const RequestStatusRow: React.FC<{ request: Request }> = ({ request }) => {
  const received = request.orders.reduce((sum, order) => sum + (order.receivedAt ? 1 : 0), 0);
  const total = request.orders.length;

  return (
    <Flex gap="small">
      <RequestStatusBadge status={request.status} />
      {(request.status === RequestStatus.SENT || request.status === RequestStatus.RECEIVED) && total && (
        <>
          <Progress
            percent={(received * 100) / total}
            status={request.status === RequestStatus.SENT ? 'active' : 'success'}
            strokeColor={{ from: '#108ee9', to: '#87d068' }}
            showInfo={false}
          />
          <Typography.Text
            type="secondary"
            className={css`
              white-space: nowrap;
            `}
          >
            {received} von {total} Aufträgen übertragen
          </Typography.Text>
        </>
      )}
    </Flex>
  );
};
