import { useQuery } from '@apollo/client';
import { graphql } from '../../../graphql/generated';
import { RequestQuery } from '../../../graphql/generated/graphql.ts';

const REQUEST_QUERY = graphql(`
  query Request($id: ID!, $doctorId: ID!) {
    request(id: $id) {
      id
      createdAt
      modifiedAt
      sentAt
      sampledAt
      status
      insuranceShortName
      insuranceName
      importType
      importRequestId
      additionalData
      comment
      orders {
        id
        number
        diagnose
        labInfo
        acute
        type
        createdAt
        createdByFirstName
        createdByLastName
        receivedAt
        invoiceNumber
        invoiceCancellationNumber
        orderLabels {
          id
          text
          text2
          number
          name
          classification
          color
          features
          printedAt
          location
          paramShortNames
          specimenId
          testTubeId
        }
        orderParameters {
          id
          shortName
          longName
          diagnose
          billingType
          specialRateCode
          specialRateShortName
          price
          acute
        }
        orderFiles {
          id
          printedAt
          name
          type
          fileName
          size
          printRequired
          printable
          pickup
        }
        orderForms {
          id
          header
          fields {
            id
            values
            label
            type
          }
        }
        lab {
          id
          name
        }
      }
      patientData {
        externalId
        title
        firstName
        lastName
        birthday
        gender
        svnr
        insuranceCategory
        street
        zip
        city
        country
        phone
        email
        idCardType
        idCardNumber
        idCardAuthority
        insuredPerson {
          title
          firstName
          lastName
          birthday
          gender
          svnr
          street
          zip
          city
          country
          phone
          email
        }
      }
      createdByLastName
      createdByFirstName
      modifiedByLastName
      modifiedByFirstName
      sentByFirstName
      sentByLastName
    }

    doctor(id: $doctorId) {
      id
      flipParamNames
      showPrices
      zplLabelTemplate
    }
  }
`);

export type Request = NonNullable<RequestQuery['request']>;
export type Order = NonNullable<RequestQuery['request']>['orders'][number];
export type OrderParameter = NonNullable<RequestQuery['request']>['orders'][number]['orderParameters'][number];
export type OrderFile = NonNullable<RequestQuery['request']>['orders'][number]['orderFiles'][number];
export type OrderLabel = NonNullable<RequestQuery['request']>['orders'][number]['orderLabels'][number];
export type OrderFormField = NonNullable<
  RequestQuery['request']
>['orders'][number]['orderForms'][number]['fields'][number];

export const useRequestDetailsQuery = (requestId: string | null, doctorId: string | null) => {
  return useQuery(REQUEST_QUERY, {
    variables: {
      id: requestId ?? '',
      doctorId: doctorId ?? '',
    },
    skip: !requestId || !doctorId,
  });
};
