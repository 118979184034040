import { App, Button, Descriptions, Space, Typography } from 'antd';
import { ClockCircleOutlined, PrinterOutlined } from '@ant-design/icons';
import { Order } from './useRequestDetailsQuery.tsx';
import dayjs from 'dayjs';
import { SHORT_DATETIME_FORMAT } from '../../../utils/dateFormatUtils.ts';
import { CostsSummary } from '../CostsSummary.tsx';
import { ImportType } from '../../../graphql/generated/graphql.ts';
import { usePrintDoctorLabel } from '../../../hooks/usePrintDoctorLabel.ts';

export const OrderTab = ({
  showPrices,
  importType,
  order,
  hasDoctorLabel,
  doctorId,
}: {
  showPrices: boolean;
  importType: ImportType | null | undefined;
  order: Order;
  hasDoctorLabel: boolean;
  doctorId: string;
}) => {
  const { message } = App.useApp();
  const printDoctorLabel = usePrintDoctorLabel();
  const diagnoses = order.orderParameters
    .reduce<string[]>(
      (diagnoses, param) => (diagnoses.some(d => d === param.diagnose) ? diagnoses : [...diagnoses, param.diagnose]),
      []
    )
    .filter(it => it !== '')
    .join('\n');

  const printDocLabel = () => {
    printDoctorLabel(
      {
        doctorIdForTemplate: doctorId,
        orderId: order.id,
      },
      1,
      () => message.error('Beim Drucken des Zuweiseretiketts ist ein Fehler aufgetreten'),
      () => message.success('Druckauftrag erstellt')
    );
  };

  return (
    <Space direction="vertical" size="middle" style={{ width: '100%' }}>
      {hasDoctorLabel && (
        <Button icon={<PrinterOutlined />} disabled={!window.nativeApi} onClick={() => printDocLabel()}>
          Zuweiseretikett drucken
        </Button>
      )}
      <Descriptions column={1} size="small" bordered styles={{ label: { width: '200px' } }}>
        <Descriptions.Item label="Auftragsnummer">{order.number}</Descriptions.Item>
        <Descriptions.Item label="Erstellt am">
          {dayjs(order.createdAt).format(SHORT_DATETIME_FORMAT)}
        </Descriptions.Item>
        <Descriptions.Item label="Erstellt von">
          {order.createdByFirstName} {order.createdByLastName}
        </Descriptions.Item>
        <Descriptions.Item label="Empfangen am">
          {order.receivedAt ? (
            dayjs(order.receivedAt).format(SHORT_DATETIME_FORMAT)
          ) : (
            <Typography.Text type="secondary">Das Labor hat den Auftrag noch nicht erhalten</Typography.Text>
          )}
        </Descriptions.Item>
        {order.acute && (
          <Descriptions.Item label="Akut angefordert">
            <ClockCircleOutlined /> Ja
          </Descriptions.Item>
        )}
        {!!order.diagnose && (
          <Descriptions.Item label="Diagnosen und Informationen für die Befundvalidierung">
            <pre style={{ marginBottom: 0, whiteSpace: 'pre-wrap' }}>{order.diagnose}</pre>
          </Descriptions.Item>
        )}
        {!!order.labInfo && <Descriptions.Item label="Informationen ans Labor">{order.labInfo}</Descriptions.Item>}
        {!!diagnoses && (
          <Descriptions.Item label="Diagnosen für die Verrechnung mit der Kasse">
            <pre style={{ marginBottom: 0, whiteSpace: 'pre-wrap' }}>{diagnoses}</pre>
          </Descriptions.Item>
        )}
        {showPrices && !importType && (
          <Descriptions.Item label="Kosten">
            <CostsSummary
              entries={order.orderParameters.map(it => ({
                billingType: it.billingType,
                price: it.price ?? null,
                text: it.specialRateShortName,
                pricePatient: null,
              }))}
              showPriceSuggestion={false}
              compact
            />
          </Descriptions.Item>
        )}
      </Descriptions>
    </Space>
  );
};
