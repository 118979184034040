import React, { useEffect, useState } from 'react';
import { App, Button, Col, Drawer, Empty, Form, Input, Row, Select, Space, Table, Typography } from 'antd';
import { useMutation } from '@apollo/client';
import { useForm } from 'antd/es/form/Form';
import { DeleteOutlined, SaveOutlined } from '@ant-design/icons';
import { ColumnsType, ColumnType } from 'antd/es/table';
import { tableActionCell } from '../../../styles/globalCss';
import { translateBillingType } from '../../../utils/enumHelpers';
import { graphql } from '../../../graphql/generated';
import { BillingType, HotKey, ProfileParameter } from '../../../graphql/generated/graphql.ts';
import { CatalogParameter, Diagnose, Profile } from '../Profiles.tsx';
import { ellipsis } from '../../../utils/ellipsis.ts';

const CREATE_PROFILE_MUTATION = graphql(`
  mutation CreateProfile($input: ProfileInput!) {
    createProfile(input: $input) {
      id
    }
  }
`);

const UPDATE_PROFILE_MUTATION = graphql(`
  mutation UpdateProfile($id: ID!, $input: ProfileInput!) {
    updateProfile(id: $id, input: $input) {
      id
    }
  }
`);

export const ProfileEditorDrawer: React.FC<{
  doctorId: string;
  usedHotKeys: HotKey[];
  catalogParameters: CatalogParameter[];
  diagnoses: Diagnose[];
  profile: Profile | null;
  flipParamNames: boolean;
  onClose: () => void;
  onSuccess: () => void;
}> = ({ doctorId, usedHotKeys, catalogParameters, diagnoses, profile, flipParamNames, onClose, onSuccess }) => {
  const [createProfileMutation, { loading: createLoading }] = useMutation(CREATE_PROFILE_MUTATION);
  const [updateProfileMutation, { loading: updateLoading }] = useMutation(UPDATE_PROFILE_MUTATION);
  const [form] = useForm<Profile>();
  const [idsToAdd, setIdsToAdd] = useState([]);
  const { message } = App.useApp();

  useEffect(() => {
    form.setFieldsValue({
      ...profile,
    });
  }, [profile, form]);

  const update = async () => {
    const values = form.getFieldsValue(true) as Profile;
    try {
      await updateProfileMutation({
        variables: {
          id: profile?.id ?? '',
          input: mapValues(values),
        },
      });

      message.success('Profil wurde bearbeitet');
      onSuccess();
      closeAndReset();
    } catch {
      message.error('Profil konnte nicht bearbeitet werden');
    }
  };

  const create = async () => {
    const values = form.getFieldsValue(true) as Profile;
    try {
      await createProfileMutation({
        variables: {
          input: mapValues(values),
        },
      });

      message.success('Profil wurde angelegt');
      onSuccess();
      closeAndReset();
    } catch {
      message.error('Profil konnte nicht angelegt werden');
    }
  };

  const mapValues = (values: Profile) => {
    return {
      name: values.name,
      hotKey: values.hotKey,
      parameters: values.parameters.map(it => ({
        parameterId: it.parameter.id,
        preferredBillingType: it.preferredBillingType,
      })),
      diagnose: values.diagnose,
      diagnoseIds: values.diagnoses.map(it => it.id),
      doctorId: doctorId, // doctor id cannot be changed, use random value
    };
  };

  const closeAndReset = () => {
    form.resetFields();
    setIdsToAdd([]);
    onClose();
  };

  const shortNameCol: ColumnType<ProfileParameter> = {
    title: 'Kurzname',
    dataIndex: ['parameter', 'shortName'],
    key: 'shortName',
    ellipsis: true,
    width: 110,
    defaultSortOrder: flipParamNames ? undefined : 'ascend',
    sorter: (a, b) => a.parameter.shortName.localeCompare(b.parameter.shortName),
  };

  const longNameCol: ColumnType<ProfileParameter> = {
    title: 'Langbezeichnung',
    dataIndex: ['parameter', 'longName'],
    key: 'longName',
    ellipsis: true,
    width: 160,
    defaultSortOrder: flipParamNames ? 'ascend' : undefined,
    sorter: (a, b) => a.parameter.longName.localeCompare(b.parameter.longName),
    render: v => ellipsis(v, 35),
  };

  const columns: ColumnsType<ProfileParameter> = [
    ...(flipParamNames ? [longNameCol, shortNameCol] : [shortNameCol, longNameCol]),
    {
      title: 'Labor',
      dataIndex: ['parameter', 'lab', 'shortName'],
      key: 'lab',
      width: 60,
      ellipsis: true,
      sorter: (a, b) => a.parameter.lab.shortName.localeCompare(b.parameter.lab.shortName),
    },
    {
      title: 'Standard-Verrechnung',
      dataIndex: 'preferredBillingType',
      key: 'preferredBillingType',
      width: 200,
      render: (_, record) => (
        <Select
          allowClear
          placeholder="Automatisch"
          size="small"
          options={Object.keys(BillingType)
            .filter(it => !it.includes(BillingType.DEFINE_LATER))
            .map(it => ({ label: translateBillingType(it), value: it }))}
          value={record.preferredBillingType}
          onChange={billingType => {
            const profile = form.getFieldsValue(true) as Profile;
            const index = profile.parameters.findIndex(it => it.id === record.id);
            if (index !== -1) {
              form.setFieldsValue({
                ...profile,
                parameters: profile.parameters.map(it =>
                  it.id === record.id ? { ...it, preferredBillingType: billingType } : it
                ),
              });
            }
          }}
          style={{ width: '100%' }}
        />
      ),
    },
    {
      title: '',
      key: 'actions',
      fixed: 'right',
      align: 'right',
      ellipsis: true,
      width: '50px',
      className: tableActionCell,
      render: (_, record) => (
        <Button
          icon={<DeleteOutlined />}
          type="text"
          danger
          onClick={() => {
            const profile = form.getFieldsValue(true) as Profile;
            form.setFieldsValue({
              ...profile,
              parameters: profile.parameters.filter(it => it.id !== record.id),
            });
          }}
        />
      ),
    },
  ];

  return (
    <Drawer
      title={profile?.id ? 'Profil bearbeiten' : 'Neues Profil anlegen'}
      forceRender
      width="60%"
      placement="right"
      onClose={onClose}
      open={!!profile}
      extra={
        <Button type="primary" onClick={form.submit} icon={<SaveOutlined />} disabled={createLoading || updateLoading}>
          Speichern
        </Button>
      }
    >
      <Form<Profile> form={form} layout="vertical" onFinish={profile?.id ? update : create}>
        <Row gutter={[16, 0]}>
          <Col span={12}>
            <Form.Item name="name" label="Bezeichnung" rules={[{ required: true, whitespace: true }]}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="hotKey" label="Tastaturkürzel">
              <Select allowClear>
                {Object.keys(HotKey).map(it => (
                  <Select.Option
                    key={it}
                    value={it}
                    disabled={it !== profile?.hotKey && usedHotKeys.includes(it as HotKey)}
                  >
                    {it}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col span={12}>
            <Form.Item name="diagnose" label="Diagnose">
              <Input.TextArea rows={3} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Diagnosen für die Verrechnung mit der Kasse"
              tooltip="Diagnosen für die Verrechnung mit der Kasse werden nur dann der Anforderung hinzugefügt, wenn sie für die Abrechnung mit der Krankenkasse vom Labor benötigt werden."
              shouldUpdate
            >
              {() => {
                const selectedDiagnoses: Diagnose[] = form.getFieldsValue(true).diagnoses ?? [];
                const diagnoseIds =
                  [...selectedDiagnoses]
                    .sort((a, b) => a.lab.shortName.localeCompare(b.lab.shortName) || a.text.localeCompare(b.text))
                    .map((it: Diagnose) => it.id) ?? [];
                return (
                  <Select<string[]>
                    showSearch
                    onChange={v => {
                      form.setFieldValue(
                        'diagnoses',
                        diagnoses.filter(it => v.includes(it.id))
                      );
                    }}
                    value={diagnoseIds}
                    placeholder="Verrechnungsdiagnosen hinzufügen"
                    allowClear
                    mode="multiple"
                    optionFilterProp="search"
                    optionLabelProp="taglabel"
                    maxTagTextLength={30}
                    notFoundContent={
                      <Empty description="Keine Diagnosen gefunden" image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    }
                    options={[...diagnoses]
                      .sort((a, b) => a.lab.shortName.localeCompare(b.lab.shortName) || a.text.localeCompare(b.text))
                      .map(diagnose => ({
                        value: diagnose.id,
                        label: (
                          <>
                            <Typography.Text type="secondary">{diagnose.lab.shortName}</Typography.Text> {diagnose.text}
                          </>
                        ),
                        search: diagnose.text,
                        taglabel: diagnose.text,
                      }))}
                  />
                );
              }}
            </Form.Item>
          </Col>
        </Row>
        <Form.Item noStyle shouldUpdate>
          {() => {
            const parameters = form.getFieldsValue(true).parameters ?? [];
            const parameterIds = parameters.map((it: ProfileParameter) => it.parameter.id) ?? [];
            return (
              <Space direction="vertical" style={{ width: '100%' }}>
                <Select
                  showSearch
                  mode="multiple"
                  allowClear
                  onChange={setIdsToAdd}
                  value={idsToAdd}
                  onDropdownVisibleChange={open => {
                    if (!open) {
                      form.setFieldsValue({
                        parameters: [
                          ...parameters,
                          ...idsToAdd.map(it => ({
                            id: it,
                            preferredBillingType: null,
                            parameter: catalogParameters.find(cp => cp.parameter.id === it)?.parameter,
                          })),
                        ],
                      });
                      setIdsToAdd([]);
                    }
                  }}
                  placeholder="Parameter hinzufügen"
                  optionFilterProp="search"
                  optionLabelProp="taglabel"
                  style={{ width: '100%' }}
                  notFoundContent={
                    <Empty description="Keine Parameter gefunden" image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  }
                  options={catalogParameters
                    .filter(it => !parameterIds.includes(it.parameter.id))
                    .sort((a, b) =>
                      flipParamNames
                        ? a.parameter.longName.localeCompare(b.parameter.longName)
                        : a.parameter.shortName.localeCompare(b.parameter.shortName)
                    )
                    .map(it => ({
                      label: (
                        <>
                          <div>{flipParamNames ? it.parameter.longName : it.parameter.shortName}</div>
                          <Typography.Text type="secondary">
                            {it.parameter.lab.shortName},{' '}
                            {flipParamNames ? it.parameter.shortName : it.parameter.longName}
                          </Typography.Text>
                        </>
                      ),
                      search: `${it.parameter.shortName} ${it.parameter.longName} ${it.parameter.synonyms.join(' ')}`,
                      taglabel: flipParamNames ? it.parameter.longName : it.parameter.shortName,
                      value: it.parameter.id,
                    }))}
                />
                <Table<ProfileParameter>
                  scroll={{ x: 'max-content' }}
                  rowKey={record => record.id}
                  size="small"
                  showSorterTooltip={false}
                  dataSource={parameters}
                  pagination={false}
                  loading={createLoading || updateLoading}
                  columns={columns}
                  locale={{
                    emptyText: (
                      <Empty description="Bitte fügen Sie Parameter hinzu" image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    ),
                  }}
                />
              </Space>
            );
          }}
        </Form.Item>
      </Form>
    </Drawer>
  );
};
