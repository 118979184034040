import React, { useEffect } from 'react';
import { MainContent } from '../../../components/MainContent';
import { useMutation, useQuery } from '@apollo/client';
import {
  App,
  Button,
  Card,
  Checkbox,
  Col,
  Divider,
  Form,
  Image,
  Input,
  InputNumber,
  Modal,
  Row,
  Space,
  Tabs,
  theme,
} from 'antd';
import { useParams } from 'react-router';
import { BarcodeOutlined, InfoCircleOutlined, MailOutlined, PhoneOutlined, SaveOutlined } from '@ant-design/icons';
import { useLabelary } from '../../../hooks/useLabelary';
import ReactCodeMirror from '@uiw/react-codemirror';
import { highlightTags, theme as codemirrorTheme, zplOrderLabel } from '../../../styles/codemirror';
import { useForm, useWatch } from 'antd/es/form/Form';
import { graphql } from '../../../graphql/generated';
import { LabInput } from '../../../graphql/generated/graphql.ts';
import { css } from '@emotion/css';
import { serverSideEmailRule } from '../../../utils/helpers.ts';

const { useToken } = theme;

const LAB_QUERY = graphql(`
  query BasedataLab($id: ID!) {
    lab(id: $id) {
      id
      name
      shortName
      longName
      meAddress
      city
      email
      fax
      phone
      street
      zip
      zplLabelTemplate
      zplLabelWidth
      zplLabelHeight
      newReorderRunningNumber
      diagnoseRequired
      pickupTransferDocument
      printPartnerLab1Params
      printPartnerLab2Params
      printPartnerLab3Params
      pl1Name
      pl2Name
      pl3Name
      runningNumber
      invoiceCounter
    }
  }
`);

const UPDATE_LAB_MUTATION = graphql(`
  mutation UpdateLab($id: ID!, $input: LabInput!) {
    updateLab(id: $id, input: $input) {
      id
    }
  }
`);

export const Basedata: React.FC = () => {
  const { token } = useToken();
  const params = useParams<{ id: string }>();
  const [form] = useForm<LabInput>();
  const overrideRunningNumber = useWatch('overrideRunningNumber', form);
  const overrideInvoiceCounter = useWatch('overrideInvoiceCounter', form);
  const { message } = App.useApp();

  const { data, loading, refetch } = useQuery(LAB_QUERY, {
    variables: {
      id: params.id ?? '',
    },
    pollInterval: 60000,
  });

  const [labelaryUrl, fetchLabelary, cleanupLabelary] = useLabelary();

  const [updateLabMutation] = useMutation(UPDATE_LAB_MUTATION);

  const update = async (values: LabInput) => {
    try {
      await updateLabMutation({
        variables: {
          id: params.id ?? '',
          input: { ...values },
        },
      });

      message.success('Die Labor-Basisdaten wurden erfolgreich aktualisiert');
      refetch();
    } catch {
      message.error('Die Labor-Basisdaten konnten nicht aktualisiert werden');
    }
  };

  const tooltip = {
    styles: { body: { width: '400px' } },
    overlay: (
      <div>
        Mögliche Variablen:
        <br />
        %PATIENT% - Patient Name
        <br />
        %PATIENTDOB% - Patient Geburtstag
        <br />
        %PATIENTAISID% - Patient externe AIS-ID
        <br />
        %SVNR% - Versicherungsnummer
        <br />
        %DOCTOR% - Zuweiser Name
        <br />
        %DOCTORID% - Zuweiser Kundennummer
        <br />
        %TEXT% - Etikettentext Probenbehälter
        <br />
        %TEXT2% - Etikettentext Probenbehälter 2
        <br />
        %LOCATION% - Lokalisation
        <br />
        %BARCODE% - Etikettennummer unformatiert
        <br />
        %ID% - UUID des Etiketts
        <br />
        %PRETTYCODE% - Etikettennummer formatiert
        <br />
        %LAB% - Labor Kurzbezeichnung
        <br />
        %FEATURES% - Materialeigenschaften
      </div>
    ),
    icon: <InfoCircleOutlined />,
  };

  useEffect(() => {
    form.setFieldsValue({
      name: data?.lab?.name ?? '',
      shortName: data?.lab?.shortName ?? '',
      longName: data?.lab?.longName ?? '',
      meAddress: data?.lab?.meAddress ?? '',
      city: data?.lab?.city ?? '',
      email: data?.lab?.email ?? '',
      fax: data?.lab?.fax ?? '',
      phone: data?.lab?.phone ?? '',
      street: data?.lab?.street ?? '',
      zip: data?.lab?.zip ?? '',
      zplLabelTemplate: data?.lab?.zplLabelTemplate ?? '',
      zplLabelWidth: data?.lab?.zplLabelWidth ?? 0,
      zplLabelHeight: data?.lab?.zplLabelHeight ?? 0,
      newReorderRunningNumber: data?.lab?.newReorderRunningNumber ?? false,
      diagnoseRequired: data?.lab?.diagnoseRequired ?? false,
      pickupTransferDocument: data?.lab?.pickupTransferDocument ?? false,
      printPartnerLab1Params: data?.lab?.printPartnerLab1Params ?? false,
      printPartnerLab2Params: data?.lab?.printPartnerLab2Params ?? false,
      printPartnerLab3Params: data?.lab?.printPartnerLab3Params ?? false,
      pl1Name: data?.lab?.pl1Name,
      pl2Name: data?.lab?.pl2Name,
      pl3Name: data?.lab?.pl3Name,
      runningNumber: data?.lab?.runningNumber ?? 0,
      overrideRunningNumber: false,
      invoiceCounter: data?.lab?.invoiceCounter ?? 0,
      overrideInvoiceCounter: false,
    });
  }, [form, data]);

  const cmTheme = codemirrorTheme(token.colorBorderSecondary, token.colorPrimaryActive);
  return (
    <MainContent>
      <Form layout="vertical" form={form} onFinish={update}>
        <Tabs
          defaultActiveKey="stammdaten"
          size="small"
          items={[
            {
              key: 'stammdaten',
              label: 'Stammdaten',
              forceRender: true,
              children: (
                <>
                  <Row gutter={[16, 0]}>
                    <Col span={8}>
                      <Form.Item name="name" label="Name" rules={[{ required: true, whitespace: true }]}>
                        <Input autoFocus />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        name="shortName"
                        label="Kurzbezeichnung"
                        rules={[{ required: true, whitespace: true }]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item name="longName" label="Langbezeichnung" rules={[{ required: true, whitespace: true }]}>
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={[16, 0]}>
                    <Col span={8}>
                      <Form.Item
                        name="street"
                        label="Straße inkl. Hausnummer"
                        rules={[{ required: true, whitespace: true }]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item name="zip" label="PLZ" rules={[{ required: true, whitespace: true }]}>
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item name="city" label="Ort" rules={[{ required: true, whitespace: true }]}>
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={[16, 0]}>
                    <Col span={8}>
                      <Form.Item name="email" label="E-Mail" rules={[serverSideEmailRule]} validateDebounce={200}>
                        <Input addonBefore={<MailOutlined />} />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item name="phone" label="Telefon">
                        <Input addonBefore={<PhoneOutlined />} />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item name="fax" label="Fax">
                        <Input addonBefore={<PhoneOutlined />} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={[16, 0]}>
                    <Col span={8}>
                      <Form.Item name="meAddress" label="ME-Adresse" rules={[{ required: true, whitespace: true }]}>
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              ),
            },
            {
              key: 'labelTemplate',
              label: 'Etiketten-Vorlage',
              forceRender: true,
              children: (
                <>
                  <Row gutter={[16, 0]}>
                    <Col span={12}>
                      <Form.Item
                        name="zplLabelWidth"
                        label="Breite ZPL-Etikett in mm für Vorschau"
                        rules={[{ type: 'integer', required: true, min: 10, max: 100, message: 'Bitte Zahl angeben' }]}
                      >
                        <InputNumber
                          inputMode="numeric"
                          required
                          min={10}
                          max={100}
                          step={1}
                          style={{ width: '100%' }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="zplLabelHeight"
                        label="Höhe ZPL-Etikett in mm für Vorschau"
                        rules={[{ type: 'integer', required: true, min: 10, max: 100, message: 'Bitte Zahl angeben' }]}
                      >
                        <InputNumber
                          inputMode="numeric"
                          required
                          min={10}
                          max={100}
                          step={1}
                          style={{ width: '100%' }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item
                    name="zplLabelTemplate"
                    label={
                      <div>
                        <span>ZPL-Etiketten-Vorlage</span>{' '}
                        <Button
                          size="small"
                          icon={<BarcodeOutlined />}
                          onClick={() =>
                            fetchLabelary(
                              form.getFieldValue('zplLabelWidth'),
                              form.getFieldValue('zplLabelHeight'),
                              form.getFieldValue('zplLabelTemplate')
                            )
                          }
                        >
                          Vorschau
                        </Button>
                      </div>
                    }
                    rules={[{ required: true, whitespace: true }]}
                    tooltip={tooltip}
                  >
                    <ReactCodeMirror
                      width="100%"
                      height="300px"
                      basicSetup={{ lineNumbers: true }}
                      extensions={[zplOrderLabel, highlightTags]}
                      theme={cmTheme}
                    />
                  </Form.Item>
                </>
              ),
            },
            {
              key: 'settings',
              label: 'Einstellungen',
              forceRender: true,
              children: (
                <Row
                  gutter={[16, 16]}
                  className={css`
                    margin-bottom: ${token.marginSM}px;
                  `}
                >
                  <Col xs={24} lg={12}>
                    <Space direction="vertical" size="middle" style={{ width: '100%' }}>
                      <Card title="Allgemeines" size="small" type="inner">
                        <Space direction="vertical">
                          <Form.Item name="newReorderRunningNumber" valuePropName="checked" noStyle>
                            <Checkbox>Neue Auftragsnummer für Nachforderungen</Checkbox>
                          </Form.Item>
                          <Form.Item name="pickupTransferDocument" valuePropName="checked" noStyle>
                            <Checkbox>Überweisungsschein-Dokument elektronisch übertragen</Checkbox>
                          </Form.Item>
                          <Form.Item name="diagnoseRequired" valuePropName="checked" noStyle>
                            <Checkbox>Diagnosepflicht</Checkbox>
                          </Form.Item>
                        </Space>
                      </Card>
                      <Card title="Laufende Auftragsnummer" size="small" type="inner">
                        <Row gutter={[16, 0]}>
                          <Col span={12}>
                            <Form.Item
                              name="runningNumber"
                              rules={[{ type: 'integer', required: true, min: 0, message: 'Bitte Zähler angeben' }]}
                            >
                              <InputNumber
                                className={css`
                                  width: 100%;
                                `}
                                precision={0}
                                min={0}
                                step={1}
                                disabled={!overrideRunningNumber}
                              />
                            </Form.Item>
                          </Col>
                          <Col>
                            <Form.Item name="overrideRunningNumber" valuePropName="checked" noStyle>
                              <Checkbox
                                className={css`
                                  margin-top: ${token.paddingXXS}px;
                                `}
                              >
                                Überschreiben
                              </Checkbox>
                            </Form.Item>
                          </Col>
                        </Row>
                      </Card>
                      <Card title="Laufende Rechnungsnummer" size="small" type="inner">
                        <Row gutter={[16, 0]}>
                          <Col span={12}>
                            <Form.Item
                              name="invoiceCounter"
                              rules={[{ type: 'integer', required: true, min: 0, message: 'Bitte Zähler angeben' }]}
                            >
                              <InputNumber
                                className={css`
                                  width: 100%;
                                `}
                                precision={0}
                                min={0}
                                step={1}
                                disabled={!overrideInvoiceCounter}
                              />
                            </Form.Item>
                          </Col>
                          <Col>
                            <Form.Item name="overrideInvoiceCounter" valuePropName="checked" noStyle>
                              <Checkbox
                                className={css`
                                  margin-top: ${token.paddingXXS}px;
                                `}
                              >
                                Überschreiben
                              </Checkbox>
                            </Form.Item>
                          </Col>
                        </Row>
                      </Card>
                    </Space>
                  </Col>
                  <Col xs={24} lg={12}>
                    <Space direction="vertical" size="middle" style={{ width: '100%' }}>
                      <Card title="Partnerlabor" size="small" type="inner">
                        <Space direction="vertical">
                          <Form.Item name="printPartnerLab1Params" valuePropName="checked" noStyle>
                            <Checkbox>Partnerlabor 1 Parameter andrucken</Checkbox>
                          </Form.Item>
                          <Form.Item name="printPartnerLab2Params" valuePropName="checked" noStyle>
                            <Checkbox>Partnerlabor 2 Parameter andrucken</Checkbox>
                          </Form.Item>
                          <Form.Item name="printPartnerLab3Params" valuePropName="checked" noStyle>
                            <Checkbox>Partnerlabor 3 Parameter andrucken</Checkbox>
                          </Form.Item>
                        </Space>
                        <Divider />
                        <Form.Item label="Name Partnerlabor 1" layout="horizontal" colon={false} name="pl1Name">
                          <Input />
                        </Form.Item>
                        <Form.Item label="Name Partnerlabor 2" layout="horizontal" colon={false} name="pl2Name">
                          <Input />
                        </Form.Item>
                        <Form.Item label="Name Partnerlabor 3" layout="horizontal" colon={false} name="pl3Name">
                          <Input />
                        </Form.Item>
                      </Card>
                    </Space>
                  </Col>
                </Row>
              ),
            },
          ]}
        />
        <Form.Item noStyle shouldUpdate>
          {() => (
            <Button
              htmlType="submit"
              type="primary"
              disabled={loading || form.getFieldsError().some(({ errors }) => errors.length)}
              icon={<SaveOutlined />}
            >
              Speichern
            </Button>
          )}
        </Form.Item>
      </Form>
      <Modal
        open={!!labelaryUrl}
        onCancel={() => cleanupLabelary()}
        onOk={() => cleanupLabelary()}
        cancelButtonProps={{ hidden: true }}
      >
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Image src={labelaryUrl} style={{ maxWidth: '100%', border: '1px solid black' }} preview={false} />
        </div>
      </Modal>
    </MainContent>
  );
};
