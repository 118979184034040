import { Alert, App, Button, Image, Modal, Popover, Space, Table, Typography } from 'antd';
import { EyeOutlined, PrinterOutlined } from '@ant-design/icons';
import { Order, OrderLabel } from './useRequestDetailsQuery.tsx';
import { useAuth } from 'react-oidc-context';
import { useMarkOrderLabelPrintedMutation } from './useMarkOrderLabelPrintedMutation.tsx';
import { ColumnsType } from 'antd/es/table';
import { TestTubeColor } from '../../../components/TestTubeColor.tsx';
import { testTubeColorProperties, translateSpecimenFeature } from '../../../utils/enumHelpers.ts';
import dayjs from 'dayjs';
import { SHORT_DATETIME_FORMAT } from '../../../utils/dateFormatUtils.ts';
import { TableList } from '../../../components/TableList.tsx';
import { tableActionCell } from '../../../styles/globalCss.ts';
import { LoadingIndicator } from '../../../components/LoadingIndicator.tsx';
import { useObjectUrlResource } from '../../../hooks/useObjectUrlResource.ts';

export const LabelsTab = ({ order, onPrinted }: { order: Order; onPrinted: () => void }) => {
  const { message } = App.useApp();
  const auth = useAuth();

  const [markOrderLabelPrintedMutation] = useMarkOrderLabelPrintedMutation();

  const {
    objectUrl: labelPreviewUrl,
    fetchAndSet: fetchLabelPreviewUrl,
    cleanup: cleanupLabelPreviewUrl,
    loading: labelPreviewLoading,
  } = useObjectUrlResource(
    () => message.error('Es ist ein Fehler beim Laden der Etiketten-Vorschau aufgetreten'),
    'image/png'
  );

  const printOrderLabels = async (orderLabels: OrderLabel[]) => {
    const sortedLabels = [...orderLabels].sort(
      (a, b) =>
        a.specimenId.localeCompare(b.specimenId) ||
        a.testTubeId.localeCompare(b.testTubeId) ||
        a.location.localeCompare(b.location)
    );
    let error = false;

    try {
      let concatenatedLabels = new ArrayBuffer(0);

      for (const label of sortedLabels) {
        const endpoint = window._env_.API_URL + `/rest/order-label/${label.id}/download`;
        const response = await fetch(endpoint, {
          headers: {
            authorization: `Bearer ${auth.user?.access_token}`,
          },
        });

        if (!response.ok) {
          error = true;
          break;
        }

        const blob = await response.blob();
        const buffer = await blob.arrayBuffer();
        // merging the labels to one print request speeds up printing significantly
        concatenatedLabels = await new Blob([concatenatedLabels, buffer]).arrayBuffer();
      }

      if (concatenatedLabels.byteLength > 0) {
        await window.nativeApi?.print(concatenatedLabels, 'label');
      }
    } catch (e) {
      error = true;
    }

    if (error) {
      message.error('Beim Drucken ist ein Fehler aufgetreten');
      return;
    }

    message.success('Druckauftrag erstellt');

    try {
      for (const label of orderLabels) {
        await markOrderLabelPrintedMutation({
          variables: {
            orderLabelId: label.id,
          },
        });
      }

      onPrinted();
    } catch (e) {
      message.error('Der Druckstatus kann nicht gesetzt werden');
    }
  };

  const labelColumns: ColumnsType<OrderLabel> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
      defaultSortOrder: 'ascend',
      width: 90,
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: v => <Typography.Text style={{ fontWeight: 500 }}>{v}</Typography.Text>,
    },
    {
      title: 'Nummer',
      dataIndex: 'number',
      key: 'number',
      ellipsis: true,
      width: 100,
      sorter: (a, b) => a.number.localeCompare(b.number),
    },
    {
      title: 'Kennung',
      dataIndex: 'classification',
      key: 'classification',
      ellipsis: true,
      width: 100,
      sorter: (a, b) => a.classification.localeCompare(b.classification),
    },
    {
      title: 'Farbe',
      dataIndex: 'color',
      key: 'color',
      width: 90,
      sorter: (a, b) => a.color.localeCompare(b.color),
      render: value => <TestTubeColor properties={testTubeColorProperties(value)} withText />,
    },
    {
      title: 'Text',
      dataIndex: 'text',
      key: 'text',
      ellipsis: true,
      width: 90,
      sorter: (a, b) => a.text.localeCompare(b.text),
    },
    {
      title: 'Text 2',
      dataIndex: 'text2',
      key: 'text2',
      ellipsis: true,
      width: 100,
      sorter: (a, b) => a.text2.localeCompare(b.text2),
    },
    {
      title: 'Lokalisation',
      dataIndex: 'location',
      key: 'location',
      ellipsis: true,
      width: 120,
      sorter: (a, b) => a.location.localeCompare(b.location),
    },
    {
      title: 'Letzter Druck am',
      dataIndex: 'printedAt',
      key: 'printedAt',
      width: 160,
      ellipsis: true,
      sorter: (a, b) => (a.printedAt ?? '').localeCompare(b.printedAt ?? ''),
      render: value => (value ? dayjs(value).format(SHORT_DATETIME_FORMAT) : ''),
    },
    {
      title: 'Eigenschaften',
      dataIndex: 'features',
      key: 'features',
      ellipsis: true,
      width: 120,
      render: (_, record) => (
        <Popover
          trigger={['click']}
          content={
            <ul
              style={{
                margin: 0,
                padding: 0,
                listStyle: 'none',
              }}
            >
              {record.features.map(feature => (
                <li key={feature}>{translateSpecimenFeature(feature)}</li>
              ))}
            </ul>
          }
        >
          <Button type="link" style={{ height: 'auto' }} icon={<EyeOutlined />} hidden={!record.features.length} />
        </Popover>
      ),
    },
    {
      title: 'Parameter',
      dataIndex: 'params',
      key: 'params',
      width: 100,
      ellipsis: true,
      render: (_, record) => <TableList entries={record.paramShortNames} maxEntries={3} withinModal />,
    },
    {
      title: '',
      key: 'actions',
      fixed: 'right',
      align: 'right',
      ellipsis: true,
      width: '90px',
      className: tableActionCell,
      render: (_, record) => (
        <>
          <Button
            icon={<EyeOutlined />}
            type="link"
            onClick={() => fetchLabelPreviewUrl(window._env_.API_URL + `/rest/order-label/${record.id}/preview`)}
          />{' '}
          <Button
            icon={<PrinterOutlined />}
            disabled={!window.nativeApi}
            type="link"
            onClick={() => printOrderLabels([record])}
          />
        </>
      ),
    },
  ];

  return order.orderLabels.length ? (
    <Space direction="vertical" size="middle" style={{ width: '100%' }}>
      <Button
        icon={<PrinterOutlined />}
        disabled={!window.nativeApi}
        onClick={() => printOrderLabels(order.orderLabels)}
      >
        Alle Etiketten drucken
      </Button>
      <Table<OrderLabel>
        scroll={{ y: '300px', x: 'max-content' }}
        rowKey={label => label.id}
        size="small"
        showSorterTooltip={false}
        dataSource={order.orderLabels}
        pagination={false}
        columns={labelColumns}
      />
      <Modal
        open={!!labelPreviewUrl || labelPreviewLoading}
        onCancel={() => cleanupLabelPreviewUrl()}
        onOk={() => cleanupLabelPreviewUrl()}
        cancelButtonProps={{ hidden: true }}
      >
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
          {labelPreviewLoading ? (
            <LoadingIndicator />
          ) : (
            <>
              <Image src={labelPreviewUrl} style={{ maxWidth: '100%', border: '1px solid black' }} preview={false} />
              <p />
              <Alert
                type="warning"
                showIcon
                message="Das angezeigte Etikett mit anonymisierten Daten dient rein als Vorschau des Originals und darf keinesfalls weiterverwendet werden."
              />
            </>
          )}
        </div>
      </Modal>
    </Space>
  ) : (
    <Typography.Text type="secondary">Es werden keine Etiketten benötigt</Typography.Text>
  );
};
