import { useMutation } from '@apollo/client';
import { graphql } from '../graphql/generated';

const SEND_REQUESTS_MUTATION = graphql(`
  mutation SendRequests($requestIds: [ID!]!) {
    sendRequests(requestIds: $requestIds) {
      id
      orders {
        id
        aisExportedAt
        orderFiles {
          id
          printedAt
          printable
          printRequired
          printBeforeSend
          printCopies
        }
        orderLabels {
          id
          printedAt
          specimenId
          testTubeId
          location
        }
      }
      additionalData
    }
  }
`);

export const useSendRequests = () => {
  const [sendRequestsMutation] = useMutation(SEND_REQUESTS_MUTATION);

  return (requestIds: string[]) =>
    sendRequestsMutation({
      variables: {
        requestIds: requestIds,
      },
    });
};
