import { App, Button, Flex, Modal, Popconfirm, Space, Table, theme, Typography } from 'antd';
import { CheckOutlined, CloseOutlined, ExceptionOutlined, EyeOutlined, PrinterOutlined } from '@ant-design/icons';
import { Order, OrderFile } from './useRequestDetailsQuery.tsx';
import { useAuth } from 'react-oidc-context';
import { ColumnsType } from 'antd/es/table';
import { translateOrderFileType } from '../../../utils/enumHelpers.ts';
import dayjs from 'dayjs';
import { SHORT_DATETIME_FORMAT } from '../../../utils/dateFormatUtils.ts';
import { tableActionCell } from '../../../styles/globalCss.ts';
import { LoadingIndicator } from '../../../components/LoadingIndicator.tsx';
import { useObjectUrlResource } from '../../../hooks/useObjectUrlResource.ts';
import { hasSomeRole, Role } from '../../../utils/user.ts';
import { OrderFileType } from '../../../graphql/generated/graphql.ts';
import { useCancelOrderInvoiceMutation } from './useCancelOrderInvoiceMutation.tsx';
import { useMarkOrderFilePrintedMutation } from './useMarkOrderFilePrintedMutation.tsx';
import { filesize } from 'filesize';
import { useTextResource } from '../../../hooks/useTextResource.ts';
import ReactCodeMirror from '@uiw/react-codemirror';
import { highlightTags, hl7, theme as codemirrorTheme } from '../../../styles/codemirror.ts';
import { PdfPreviewModal } from '../../../components/PdfPreviewModal.tsx';
import { useState } from 'react';

export const FilesTab = ({ order, onRefetchRequested }: { order: Order; onRefetchRequested: () => void }) => {
  const { message } = App.useApp();
  const auth = useAuth();
  const { token } = theme.useToken();
  const [cancelOrderInvoiceMutation] = useCancelOrderInvoiceMutation();
  const [markOrderFilePrintedMutation] = useMarkOrderFilePrintedMutation();

  const cmTheme = codemirrorTheme(token.colorBorderSecondary, token.colorPrimaryActive);
  const printableOrderFiles = order.orderFiles.filter(it => it.printable);

  const [previewOrderFile, setPreviewOrderFile] = useState<OrderFile | null>(null);

  const closeOrderFile = () => {
    resetHl7Content();
    cleanupPdfPreviewUrl();
    setPreviewOrderFile(null);
  };

  const [hl7Content, fetchHl7Content, resetHl7Content, hl7ContentLoading] = useTextResource(() =>
    message.error('Es ist ein Fehler beim Laden des Dokuments aufgetreten')
  );
  const {
    objectUrl: pdfPreviewUrl,
    fetchAndSet: fetchPdfPreviewUrl,
    cleanup: cleanupPdfPreviewUrl,
    loading: pdfPreviewLoading,
  } = useObjectUrlResource(
    () => message.error('Es ist ein Fehler beim Laden des Dokuments aufgetreten'),
    'application/pdf'
  );

  const printOrderFiles = async (orderFiles: OrderFile[]) => {
    let error = false;

    for (const file of orderFiles) {
      const endpoint = window._env_.API_URL + `/rest/order-file/${file.id}`;
      try {
        const response = await fetch(endpoint, {
          headers: {
            authorization: `Bearer ${auth.user?.access_token}`,
          },
        });

        if (!response.ok) {
          error = true;
          continue;
        }

        const blob = await response.blob();
        const buffer = await blob.arrayBuffer();
        await window.nativeApi?.print(buffer, 'a4');
      } catch (e) {
        error = true;
      }
    }

    if (error) {
      message.error('Beim Drucken ist ein Fehler aufgetreten');
      return;
    }

    message.success('Druckauftrag erstellt');

    try {
      for (const file of orderFiles) {
        await markOrderFilePrintedMutation({
          variables: {
            orderFileId: file.id,
          },
        });
      }

      onRefetchRequested();
    } catch (e) {
      message.error('Der Druckstatus kann nicht gesetzt werden');
    }
  };

  const fileColumns: ColumnsType<OrderFile> = [
    {
      title: 'Bezeichnung',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
      defaultSortOrder: 'ascend',
      width: 100,
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: v => <Typography.Text style={{ fontWeight: 500 }}>{v}</Typography.Text>,
    },
    {
      title: 'Typ',
      dataIndex: 'type',
      key: 'type',
      ellipsis: true,
      width: 80,
      sorter: (a, b) => a.type.localeCompare(b.type),
      render: value => translateOrderFileType(value),
    },
    {
      title: 'Dateigröße',
      dataIndex: 'size',
      key: 'size',
      width: 110,
      sorter: (a, b) => a.size - b.size,
      render: (_, record) => filesize(record.size, { locale: 'de' }) as string,
    },
    {
      title: 'Letzter Druck am',
      dataIndex: 'printedAt',
      key: 'printedAt',
      width: 150,
      sorter: (a, b) => (a.printedAt ?? '').localeCompare(b.printedAt ?? ''),
      render: value => (value ? dayjs(value).format(SHORT_DATETIME_FORMAT) : ''),
    },
    {
      title: 'Elekt. übertragen',
      dataIndex: 'pickup',
      key: 'pickup',
      width: 150,
      ellipsis: true,
      sorter: (a, b) => Number(a.pickup) - Number(b.pickup),
      render: value => (value ? <CheckOutlined /> : ''),
    },
    {
      title: '',
      key: 'actions',
      fixed: 'right',
      align: 'right',
      ellipsis: true,
      width: '60px',
      className: tableActionCell,
      render: (_, record) => (
        <Flex>
          <Button icon={<EyeOutlined />} type="link" onClick={() => showOrderFile(record)} />
          {record.printable && (
            <Button
              icon={<PrinterOutlined />}
              type="link"
              disabled={!window.nativeApi}
              onClick={() => printOrderFiles([record])}
            />
          )}
        </Flex>
      ),
    },
  ];

  const cancelOrderInvoice = async (orderId: string) => {
    try {
      await cancelOrderInvoiceMutation({
        variables: {
          orderId: orderId,
        },
      });

      onRefetchRequested();
      message.success('Die Rechnung wurde erfolgreich storniert');
    } catch (e) {
      message.error('Die Rechnung konnte nicht storniert werden');
    }
  };

  const showOrderFile = (orderFile: OrderFile) => {
    const endpoint = window._env_.API_URL + `/rest/order-file/${orderFile.id}`;
    if (orderFile.type === OrderFileType.HL7) {
      fetchHl7Content(endpoint);
    }
    if (
      orderFile.type === OrderFileType.TRANSFER_DOCUMENT ||
      orderFile.type === OrderFileType.FORM_DOCUMENT ||
      orderFile.type === OrderFileType.INVOICE
    ) {
      fetchPdfPreviewUrl(endpoint);
    }
    setPreviewOrderFile(orderFile);
  };

  return order.orderFiles.length ? (
    <Space direction="vertical" size="middle" style={{ width: '100%' }}>
      <Flex gap="small">
        <Button
          icon={<PrinterOutlined />}
          disabled={!printableOrderFiles.length || !window.nativeApi}
          onClick={() => printOrderFiles(printableOrderFiles)}
        >
          Alle Dokumente drucken
        </Button>
        {hasSomeRole([Role.ROLE_LR_MEDCOM, Role.ROLE_LR_FRONT_DESK], auth.user) &&
          order.invoiceNumber &&
          !order.invoiceCancellationNumber && (
            <Popconfirm
              title="Rechnung stornieren?"
              onConfirm={() => cancelOrderInvoice(order.id)}
              okText="Ja, stornieren"
              okButtonProps={{ danger: true }}
              cancelText="Abbrechen"
              placement="bottom"
            >
              <Button icon={<ExceptionOutlined />} danger>
                Storno-Rechnung erzeugen
              </Button>
            </Popconfirm>
          )}
      </Flex>
      <Table<OrderFile>
        scroll={{ y: '300px', x: 'max-content' }}
        rowKey={file => file.id}
        size="small"
        showSorterTooltip={false}
        dataSource={order.orderFiles.filter(it => it.type !== OrderFileType.ETRON)}
        pagination={false}
        columns={fileColumns}
      />
      <Modal
        title={previewOrderFile?.fileName}
        open={hl7Content.length > 0 || hl7ContentLoading}
        width="80%"
        style={{ maxWidth: '1200px' }}
        cancelButtonProps={{ hidden: true }}
        okButtonProps={{ icon: <CloseOutlined /> }}
        okText="Schließen"
        onOk={closeOrderFile}
        onCancel={closeOrderFile}
        destroyOnClose
      >
        {hl7ContentLoading ? (
          <LoadingIndicator />
        ) : (
          <ReactCodeMirror
            value={hl7Content}
            width="100%"
            height="400px"
            basicSetup={{ lineNumbers: true }}
            extensions={[hl7, highlightTags]}
            theme={cmTheme}
            editable={false}
            // wait for the modal transition to finish
            onCreateEditor={(view, state) => setTimeout(() => view.setState(state), 300)}
          />
        )}
      </Modal>
      <PdfPreviewModal
        title={previewOrderFile?.fileName ?? ''}
        url={pdfPreviewUrl}
        loading={pdfPreviewLoading}
        onClose={closeOrderFile}
      />
    </Space>
  ) : (
    <Typography.Text type="secondary">Keine Dokumente vorhanden</Typography.Text>
  );
};
