import React, { useEffect, useState } from 'react';
import { Modal, Tabs, Tag } from 'antd';
import { CloseOutlined, InfoCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { LoadingIndicator } from '../../components/LoadingIndicator';
import { css } from '@emotion/css';
import { OrderType } from '../../graphql/generated/graphql.ts';
import { useCurrentContextStore } from '../../hooks/store/useCurrentContextStore.ts';
import { useRequestDetailsQuery } from './details/useRequestDetailsQuery.tsx';
import { RequestTab } from './details/RequestTab.tsx';
import { LabelsTab } from './details/LabelsTab.tsx';
import { PatientTab } from './details/PatientTab.tsx';
import { FilesTab } from './details/FilesTab.tsx';
import { OrderTab } from './details/OrderTab.tsx';
import { ParametersTab } from './details/ParametersTab.tsx';
import { FormsTab } from './details/FormsTab.tsx';
import { patientFullName } from '../../utils/patient.ts';
import { hasFrontDeskRole } from '../../utils/user.ts';
import { useAuth } from 'react-oidc-context';

export const RequestDetailsModal: React.FC<{ requestId: string | null; onClose: () => void }> = ({
  requestId,
  onClose,
}) => {
  const { currentDoctorId, primaryDoctorId } = useCurrentContextStore();
  const auth = useAuth();
  const isFrontDesk = hasFrontDeskRole(auth.user);
  const doctorId = isFrontDesk ? primaryDoctorId : currentDoctorId;

  const [activeKey1, setActiveKey1] = useState('auftrag1');
  const [activeKey2, setActiveKey2] = useState('parameter');

  const { data, loading, refetch } = useRequestDetailsQuery(requestId, doctorId);

  useEffect(() => {
    // fix out of bounds tab key
    const tabs =
      [...(data?.request?.orders ?? [])]
        .sort((a, b) => a.createdAt.localeCompare(b.createdAt))
        .map((_, index) => `auftrag${index + 1}`) ?? [];
    if (activeKey1.startsWith('auftrag') && !tabs.includes(activeKey1)) {
      setActiveKey1('auftrag1');
    }
  }, [data, activeKey1]);

  const showPrices = data?.doctor?.showPrices ?? true;
  const flipParamNames = data?.doctor?.flipParamNames ?? false;
  const hasDoctorLabel = (data?.doctor?.zplLabelTemplate ?? '').length > 0;

  return (
    <Modal
      title={
        <>
          <InfoCircleOutlined /> Anforderung {data?.request && patientFullName(data.request.patientData)}{' '}
          {data?.request?.importType ? (
            <Tag color="warning" className={css(`vertical-align: bottom`)}>
              Importiert
            </Tag>
          ) : null}
        </>
      }
      okText="Schließen"
      okButtonProps={{ icon: <CloseOutlined /> }}
      open={!!requestId}
      onOk={onClose}
      onCancel={onClose}
      cancelButtonProps={{ hidden: true }}
      width="80%"
      style={{ minWidth: '768px', maxWidth: '1200px' }}
    >
      {loading ? (
        <LoadingIndicator height="200px" />
      ) : (
        <Tabs
          activeKey={activeKey1}
          size="small"
          onChange={setActiveKey1}
          items={[
            ...[
              {
                key: 'anforderung',
                label: 'Anforderung',
                children: data?.request ? <RequestTab request={data.request} onRefetchRequested={refetch} /> : null,
              },
              {
                key: 'patient',
                label: 'Patient',
                children: data?.request ? <PatientTab request={data.request} /> : null,
              },
            ],
            ...[...(data?.request?.orders ?? [])]
              .sort((a, b) => a.createdAt.localeCompare(b.createdAt))
              .map((order, index) => {
                const tabs = [
                  {
                    key: 'auftrag',
                    label: 'Auftrag',
                    children: (
                      <OrderTab
                        order={order}
                        showPrices={showPrices}
                        importType={data?.request?.importType}
                        hasDoctorLabel={hasDoctorLabel}
                        doctorId={doctorId}
                      />
                    ),
                  },
                  {
                    key: 'parameter',
                    label: 'Parameter',
                    children: <ParametersTab order={order} showPrices={showPrices} flipParamNames={flipParamNames} />,
                  },
                  {
                    key: 'etiketten',
                    label: 'Etiketten',
                    children: <LabelsTab order={order} onPrinted={refetch} />,
                  },
                  {
                    key: 'files',
                    label: 'Dokumente',
                    children: <FilesTab order={order} onRefetchRequested={refetch} />,
                  },
                  {
                    key: 'forms',
                    label: 'Formulare',
                    children: <FormsTab order={order} />,
                  },
                ];

                return {
                  key: `auftrag${index + 1}`,
                  label:
                    order.type === OrderType.INITIAL ? (
                      <>Auftrag {order.lab.name}</>
                    ) : (
                      <>
                        <PlusCircleOutlined style={{ marginRight: '2px' }} /> Nachf. {order.lab.name}
                      </>
                    ),
                  children: <Tabs type="card" activeKey={activeKey2} onChange={setActiveKey2} items={tabs} />,
                };
              }),
          ]}
        />
      )}
    </Modal>
  );
};
